import { TPiecesType } from 'modules/payment';
import { Epic } from 'redux-observable';
import { of, throwError } from 'rxjs';
import { mergeMap, catchError, filter, switchMap } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createAction,
} from 'typesafe-actions';

import {
    API_FetchFinancialPiecesRequest,
    API_FetchFinancialPiecesResponse,
    API_FetchOnePieceRequest,
} from 'api/payment';
import { RootAction, Services, RootState } from 'Types';

import {
    FETCH_FINANCIAL_PIECES_REQUEST,
    FETCH_FINANCIAL_PIECES_SUCCESS,
    FETCH_FINANCIAL_PIECES_FAILURE,
    FETCH_ONE_PIECES_REQUEST,
    FETCH_ONE_PIECES_SUCCESS,
    FETCH_ONE_PIECES_FAILURE,
    INIT_PIECES_LIST,
} from './actionTypes';

import { checkError } from 'utils/helpers/api';
import { addPaginationToPayload } from 'utils/helpers/pagination';

const resetPiecesList = createAction(INIT_PIECES_LIST)<TPiecesType>();

const fetchFinancialPiecesAsync = createAsyncAction(
    FETCH_FINANCIAL_PIECES_REQUEST,
    FETCH_FINANCIAL_PIECES_SUCCESS,
    FETCH_FINANCIAL_PIECES_FAILURE,
)<API_FetchFinancialPiecesRequest, API_FetchFinancialPiecesResponse, any>();

const fetchOnePieceAsync = createAsyncAction(
    FETCH_ONE_PIECES_REQUEST,
    FETCH_ONE_PIECES_SUCCESS,
    FETCH_ONE_PIECES_FAILURE,
)<API_FetchOnePieceRequest, API_FetchFinancialPiecesResponse, any>();

export type PiecesAction =
    | ActionType<typeof fetchOnePieceAsync>
    | ActionType<typeof fetchFinancialPiecesAsync>
    | ActionType<typeof resetPiecesList>;

const preparePayloadFinancialPieces = (
    payload: API_FetchFinancialPiecesRequest,
    withPagination?: boolean,
) => (withPagination ? addPaginationToPayload({ ...payload }) : { ...payload });

const mapGetFinancialPieces = (
    action: RootAction,
    { apiRequest }: Services,
    withPagination?: boolean,
) => {
    const payload = preparePayloadFinancialPieces(
        action.payload,
        withPagination,
    );
    return apiRequest<API_FetchFinancialPiecesResponse>({
        path: '/financialPieces',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: API_FetchFinancialPiecesResponse) => of(response)),
        catchError((error) => of(error)),
    );
};

const fetchFinancialPiecesEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(fetchFinancialPiecesAsync.request)),

        switchMap(
            (action: RootAction) =>
                mapGetFinancialPieces(action, dependency, true),
            (action: RootAction, r: any) => [action, r],
        ),

        switchMap(([action, financialPiecesResponse]) => {
            if (!checkError(financialPiecesResponse))
                return of(
                    fetchFinancialPiecesAsync.success(financialPiecesResponse),
                );

            return of(
                fetchFinancialPiecesAsync.failure(financialPiecesResponse),
            );
        }),

        catchError((error) => {
            console.log('error', error);
            return of(fetchFinancialPiecesAsync.failure(error));
        }),
    );

const mapGetOnePiece = (action: RootAction, { apiRequest }: Services) => {
    return apiRequest<API_FetchFinancialPiecesResponse>({
        path: '/financialPieces',
        method: 'post',
        body: action.payload,
    }).pipe(
        mergeMap((response: API_FetchFinancialPiecesResponse) => {
            return of(response);
        }),

        catchError((error) => {
            return throwError(error);
        }),
    );
};

const fetchOnePieceEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchOnePieceAsync.request)),

        switchMap(
            (action: RootAction) => mapGetOnePiece(action, dependency),
            (action: RootAction, r: any) => [action, r],
        ),
        switchMap(([action, financialPiecesResponse]) => {
            if (!checkError(financialPiecesResponse))
                return of(fetchOnePieceAsync.success(financialPiecesResponse));

            return of(fetchOnePieceAsync.failure(financialPiecesResponse));
        }),
        catchError((error) => {
            return of(fetchOnePieceAsync.failure(error));
        }),
    );

export {
    fetchFinancialPiecesAsync,
    fetchFinancialPiecesEpic,
    fetchOnePieceEpic,
    mapGetOnePiece,
    fetchOnePieceAsync,
    resetPiecesList,
    mapGetFinancialPieces,
};
