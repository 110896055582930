import { TAuthorizationSchema, TUser } from 'Models';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as actions from '../_actions/auth.actions';
import * as userActions from '../_actions/modules/payment/user.actions';

export type AuthState = Readonly<{
    error?: {
        errorMessage: string;
        type: 'login' | 'reset';
    };
    token: string;
    user: TUser;
    authorizationsSchema: TAuthorizationSchema[];
}>;

const initialState: AuthState = {
    error: undefined,
    token: '',
    user: {
        civility: '',
        defaultLanguage: '',
        entity: '',
        email: '',
        firstName: '',
        group: '',
        lastName: '',
        phone: '',
        picture: '',
        subGroup: '',
        userName: '',
        userStatus: '',
    },
    authorizationsSchema: [],
};

type Actions = actions.AuthAction | userActions.UserAction;

const authReducer: Reducer<AuthState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchLoginAsync.request): {
            return {
                ...state,
                error: undefined,
            };
        }

        case getType(actions.fetchLoginAsync.failure): {
            return {
                ...state,
                error: {
                    type: 'login',
                    errorMessage:
                        action.payload.errors[0].description ||
                        action.payload.message,
                },
            };
        }

        case getType(actions.resetPasswordAsync.success): {
            return {
                ...state,
                error: {
                    type: 'reset',
                    errorMessage: action.payload,
                },
            };
        }

        case getType(actions.resetPasswordAsync.failure): {
            return {
                ...state,
                error: {
                    type: 'reset',
                    errorMessage:
                        action.payload.description || action.payload.message,
                },
            };
        }

        case getType(actions.fetchLoginAsync.success): {
            const { user, token, authorizationsScheme } = action.payload;
            return {
                ...state,
                user,
                token,
                authorizationsSchema: authorizationsScheme,
                error: undefined,
            };
        }

        default:
            return state;
    }
};

export default authReducer;
