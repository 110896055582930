/* eslint-disable */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

import commonFR from '../assets/locales/fr/common.json';
import authFR from '../assets/locales/fr/auth.json';
import userFR from '../assets/locales/fr/user.json';
import profileFR from '../assets/locales/fr/profile.json';

import header_modulesFR from '../assets/locales/fr/header/modules.json';
import header_notifsFR from '../assets/locales/fr/header/notifications.json';
import header_settingsFR from '../assets/locales/fr/header/settings.json';

// BY MODULES

import payment_lettering_searchFR from '../assets/locales/fr/payment/lettering/search.json';
import payment_lettering_resultFR from '../assets/locales/fr/payment/lettering/result.json';
import payment_lettering_reconcile_modalFR from '../assets/locales/fr/payment/lettering/reconcile-modal.json';

import payment_search_searchFR from '../assets/locales/fr/payment/search/search.json';
import payment_search_financial_piecesFR from '../assets/locales/fr/payment/search/financial-pieces.json';
import payment_search_customerFR from '../assets/locales/fr/payment/search/customer.json';

import payment_monitoring_resultsFR from '../assets/locales/fr/payment/monitoring/results.json';
import payment_monitoring_searchFR from '../assets/locales/fr/payment/monitoring/search.json';

import payment_revival_resultsFR from '../assets/locales/fr/payment/revival/results.json';
import payment_revival_searchFR from '../assets/locales/fr/payment/revival/search.json';
import payment_revival_logModalFR from '../assets/locales/fr/payment/revival/logModal.json';

import payment_userFR from '../assets/locales/fr/payment/user/index.json';
import payment_authSchemaFR from '../assets/locales/fr/payment/user/auth-schema.json';

// ---
import customer_care_customer_searchFR from 'assets/locales/fr/customer-care/customer/search-results.json';
import customer_care_customer_viewFR from 'assets/locales/fr/customer-care/customer/view.json';

import customer_care_perimeter_viewFR from 'assets/locales/fr/customer-care/perimeter/view.json';

import customer_care_contract_search_resultsFR from 'assets/locales/fr/customer-care/contract/search-results.json';
import customer_care_contract_viewFR from 'assets/locales/fr/customer-care/contract/view.json';

import customer_care_activity_listFR from 'assets/locales/fr/customer-care/activity/list.json';

import customer_care_pos_viewFR from 'assets/locales/fr/customer-care/pos/view.json';

// COMMON

import common_fileDropzoneFR from '../assets/locales/fr/common/filesDropzone.json';
import common_modalFR from 'assets/locales/fr/common/modal.json';
import common_tableFR from 'assets/locales/fr/common/table.json';

import container_balance_modalFR from 'assets/locales/fr/container/balance-modal.json';
import container_infos_cardFR from 'assets/locales/fr/container/infos-card.json';

// -- EN
import commonEN from '../assets/locales/en/common.json';
import authEN from '../assets/locales/en/auth.json';
import userEN from '../assets/locales/en/user.json';
import profileEN from '../assets/locales/en/profile.json';

import header_modulesEN from '../assets/locales/en/header/modules.json';
import header_notifsEN from '../assets/locales/en/header/notifications.json';
import header_settingsEN from '../assets/locales/en/header/settings.json';

// BY MODULES

import payment_lettering_searchEN from '../assets/locales/en/payment/lettering/search.json';
import payment_lettering_resultEN from '../assets/locales/en/payment/lettering/result.json';
import payment_lettering_reconcile_modalEN from '../assets/locales/en/payment/lettering/reconcile-modal.json';

import payment_search_searchEN from '../assets/locales/en/payment/search/search.json';
import payment_search_financial_piecesEN from '../assets/locales/en/payment/search/financial-pieces.json';
import payment_search_customerEN from '../assets/locales/en/payment/search/customer.json';

import payment_monitoring_resultsEN from '../assets/locales/en/payment/monitoring/results.json';
import payment_monitoring_searchEN from '../assets/locales/en/payment/monitoring/search.json';

import payment_revival_resultsEN from '../assets/locales/en/payment/revival/results.json';
import payment_revival_searchEN from '../assets/locales/en/payment/revival/search.json';
import payment_revival_logModalEN from '../assets/locales/en/payment/revival/logModal.json';

import payment_userEN from '../assets/locales/en/payment/user/index.json';
import payment_authSchemaEN from '../assets/locales/en/payment/user/auth-schema.json';

// ---
import customer_care_customer_searchEN from 'assets/locales/en/customer-care/customer/search-results.json';
import customer_care_customer_viewEN from 'assets/locales/en/customer-care/customer/view.json';

import customer_care_perimeter_viewEN from 'assets/locales/en/customer-care/perimeter/view.json';

import customer_care_contract_search_resultsEN from 'assets/locales/en/customer-care/contract/search-results.json';
import customer_care_contract_viewEN from 'assets/locales/en/customer-care/contract/view.json';

import customer_care_activity_listEN from 'assets/locales/en/customer-care/activity/list.json';

import customer_care_pos_viewEN from 'assets/locales/en/customer-care/pos/view.json';

// COMMON

import common_fileDropzoneEN from '../assets/locales/en/common/filesDropzone.json';
import common_modalEN from 'assets/locales/en/common/modal.json';
import common_tableEN from 'assets/locales/en/common/table.json';

import container_balance_modalEN from 'assets/locales/en/container/balance-modal.json';
import container_infos_cardEN from 'assets/locales/en/container/infos-card.json';

export const resources = {
    fr: {
        common: commonFR,
        auth: authFR,
        user: userFR,
        profile: profileFR,

        'header/modules': header_modulesFR,
        'header/notifications': header_notifsFR,
        'header/settings': header_settingsFR,

        'payment/search/search': payment_search_searchFR,
        'payment/search/pieces': payment_search_financial_piecesFR,
        'payment/search/customer': payment_search_customerFR,

        'payment/monitoring/results': payment_monitoring_resultsFR,
        'payment/monitoring/search': payment_monitoring_searchFR,

        'payment/lettering/search': payment_lettering_searchFR,
        'payment/lettering/result': payment_lettering_resultFR,
        'payment/lettering/reconcile-modal':
            payment_lettering_reconcile_modalFR,

        'payment/revival/results': payment_revival_resultsFR,
        'payment/revival/search': payment_revival_searchFR,
        'payment/revival/logModal': payment_revival_logModalFR,

        'payment/user': payment_userFR,
        'payment/user/auth-schema': payment_authSchemaFR,

        'customer_care/customer/search-results':
            customer_care_customer_searchFR,
        'customer_care/customer/view': customer_care_customer_viewFR,

        'customer_care/perimeter/view': customer_care_perimeter_viewFR,

        'customer_care/contract/search-results':
            customer_care_contract_search_resultsFR,
        'customer_care/contract/view': customer_care_contract_viewFR,

        'customer_care/activity/list': customer_care_activity_listFR,

        'customer_care/pos/view': customer_care_pos_viewFR,

        'common/file-dropzone': common_fileDropzoneFR,
        'common/modal': common_modalFR,
        'common/table': common_tableFR,

        'container/balance-modal': container_balance_modalFR,
        'container/infos-card': container_infos_cardFR,
    },

    en: {
        common: commonEN,
        auth: authEN,
        user: userEN,
        profile: profileEN,

        'header/modules': header_modulesEN,
        'header/notifications': header_notifsEN,
        'header/settings': header_settingsEN,

        'payment/search/search': payment_search_searchEN,
        'payment/search/pieces': payment_search_financial_piecesEN,
        'payment/search/customer': payment_search_customerEN,

        'payment/monitoring/results': payment_monitoring_resultsEN,
        'payment/monitoring/search': payment_monitoring_searchEN,

        'payment/lettering/search': payment_lettering_searchEN,
        'payment/lettering/result': payment_lettering_resultEN,
        'payment/lettering/reconcile-modal':
            payment_lettering_reconcile_modalEN,

        'payment/revival/results': payment_revival_resultsEN,
        'payment/revival/search': payment_revival_searchEN,
        'payment/revival/logModal': payment_revival_logModalEN,

        'payment/user': payment_userEN,
        'payment/user/auth-schema': payment_authSchemaEN,

        'customer_care/customer/search-results':
            customer_care_customer_searchEN,
        'customer_care/customer/view': customer_care_customer_viewEN,

        'customer_care/perimeter/view': customer_care_perimeter_viewEN,

        'customer_care/contract/search-results':
            customer_care_contract_search_resultsEN,
        'customer_care/contract/view': customer_care_contract_viewEN,

        'customer_care/activity/list': customer_care_activity_listEN,

        'customer_care/pos/view': customer_care_pos_viewEN,

        'common/file-dropzone': common_fileDropzoneEN,
        'common/modal': common_modalEN,
        'common/table': common_tableEN,

        'container/balance-modal': container_balance_modalEN,
        'container/infos-card': container_infos_cardEN,
    },
};

i18n.use(XHR)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'fr',
        fallbackLng: ['en', 'fr'],
        debug: false,

        react: {
            wait: true,
            useSuspense: true,
        },

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
