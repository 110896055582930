import { isEmpty, isObject, isString } from 'lodash';

type Error =
    | {
          code: number;
          message?: string;
          description?: string;
          errors: Array<{
              code: number;
              message: string;
          }>;
      }
    | string;

const getErrorFromObject = (response: any) =>
    response.description || response.message;

const manageMessage = (response: Error | any) => {
    if (!response) {
        return undefined;
    }

    if (isString(response)) {
        return response;
    }

    if (isObject(response as Error)) {
        return response.errors && !isEmpty(response.errors)
            ? getErrorFromObject(response.errors[0])
            : getErrorFromObject(response);
    }

    return undefined;
};

const checkError = (response: any) => response.code || response.errors;

export { manageMessage, checkError };
