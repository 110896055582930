import {
    Search as SearchIcon,
    Users as UsersIcon,
    User as UserIcon,
    Monitor as MonitorIcon,
    PieChart as PieChartIcon,
    FileText as FileTextIcon,
    Archive as ArchiveIcon,
} from 'react-feather';

import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import ListAltIcon from '@material-ui/icons/ListAlt';

import { TBuildRoute } from 'Types';
import { isCurrentByModuleId } from './modules';
import { TModulesId } from 'modules/core';
import i18next from 'i18next';

const getIcon = (rootName: string) => {
    switch (rootName) {
        case 'search':
        case 'search_v2':
            return SearchIcon;

        case 'lettering':
            return ListAltIcon;

        case 'revival':
            return FlipCameraAndroidIcon;

        case 'monitoring':
            return MonitorIcon;

        case 'user':
            return UsersIcon;

        case 'profile':
            return UserIcon;

        case 'dashboard':
            return PieChartIcon;

        case 'customers':
            return UsersIcon;

        case 'contracts':
            return FileTextIcon;

        case 'orders':
            return ArchiveIcon;

        default:
            break;
    }
};

const buildAllNavConfig = (routes: TBuildRoute[]) => {
    return routes
        .filter((r) => r.isModule)
        .map((r) => ({
            subheader: r.label,
            items:
                r.routes &&
                r.routes
                    .filter((sr) => sr.label)
                    .map((r) => ({
                        title: r.label || '',
                        href: r.path,
                        icon: getIcon(r.rootName),
                        items: r.subMenu?.map((sm) => ({
                            href: r.path,
                            title: sm.label,
                        })),
                    })),
        }));
};

const buildNavConfigByPathname = (
    routes: TBuildRoute[],
    pathname: string,
    t: i18next.TFunction,
) => {
    const elms = routes.find(
        (r) =>
            r.isModule &&
            isCurrentByModuleId(pathname, r.moduleId as TModulesId),
    );
    const labeledModules: object = t('modules', {
        returnObjects: true,
    });
    const labeledRoutes: object =
        elms && elms.moduleId
            ? t('menu', { returnObjects: true })[elms.moduleId]
            : null;

    return elms
        ? [
              {
                  subheader: elms.moduleId
                      ? labeledModules[elms.moduleId]
                          ? labeledModules[elms.moduleId]
                          : ''
                      : '',
                  items:
                      elms.routes &&
                      elms.routes
                          .filter((sr) => sr.label)
                          .map((r) => ({
                              title:
                                  labeledRoutes && labeledRoutes[r.rootName]
                                      ? labeledRoutes[r.rootName].label
                                      : '',
                              href: r.path,
                              icon: getIcon(r.rootName),
                              items: r.subMenu?.map((sm) => ({
                                  href: r.path,
                                  title:
                                      labeledRoutes &&
                                      labeledRoutes[r.rootName] &&
                                      labeledRoutes[r.rootName].subMenu[sm.root]
                                          ? labeledRoutes[r.rootName].subMenu[
                                                sm.root
                                            ]
                                          : sm.label,
                              })),
                          })),
              },
          ]
        : [];
};

export { buildAllNavConfig, buildNavConfigByPathname };
