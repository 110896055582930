import {
    buildPaginationReducer,
    getType,
    buildActionsByKey,
} from 'libraries/Pagination';
import { CallbacksProps } from 'lib/pagination';
import {
    fetchCustomersAsync,
    fetchContractsAsync,
    resetContracts,
    resetCustomers,
} from '_actions/modules/customer-care';

export default buildPaginationReducer({
    ...buildActionsByKey('customers', [
        getType(fetchCustomersAsync.request),
        getType(fetchCustomersAsync.success),
        getType(resetCustomers),
    ]),

    ...buildActionsByKey('contracts', [
        getType(fetchContractsAsync.request),
        getType(fetchContractsAsync.success),
        getType(resetContracts),
    ]),
} as CallbacksProps);
