import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, ActionType, isActionOf } from 'typesafe-actions';
import { RootAction, RootState, Services, ReducerError, TError } from 'Types';

import {
    FETCH_GROUPS_REQUEST,
    FETCH_GROUPS_FAILURE,
    FETCH_GROUPS_SUCCESS,
    CREATE_GROUP_REQUEST,
    CREATE_GROUP_FAILURE,
    CREATE_GROUP_SUCCESS,
} from './actionTypes';
import { EErrorsType } from '../../../constants/enums';
import { TAuthorizationSchema } from 'Models';

export type TCreateGroupRequest = {
    group: string;
    authorisationSchema?: TAuthorizationSchema[];
};

export type TFetchGroupResponse = {
    groupList: string[];
};

export type TFetchGroupRequest = {
    group: string;
};

const fetchGroupsAsync = createAsyncAction(
    FETCH_GROUPS_REQUEST,
    FETCH_GROUPS_SUCCESS,
    FETCH_GROUPS_FAILURE,
)<TFetchGroupRequest, TFetchGroupResponse, ReducerError>();

const createGroupAsync = createAsyncAction(
    CREATE_GROUP_REQUEST,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILURE,
)<TCreateGroupRequest, any, TError | TError[]>();

export type GroupAction =
    | ActionType<typeof fetchGroupsAsync>
    | ActionType<typeof createGroupAsync>;

const mapFetchGroups = (action: RootAction, { apiRequest }: Services) => {
    return apiRequest<string>({
        path: '/getGroup',
        method: 'post',
        body: action.payload,
    }).pipe(
        mergeMap((response: any) => {
            return of(fetchGroupsAsync.success(response));
        }),

        catchError((error) => {
            return of(
                fetchGroupsAsync.failure({
                    errType: EErrorsType.FETCH_GROUPS,
                    error,
                }),
            );
        }),
    );
};

const preparePayloadCreateGroup = (payload: TCreateGroupRequest) => ({
    ...payload,
});

const mapCreateGroup = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadCreateGroup(action.payload);
    return apiRequest<string>({
        path: '/createGroup',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any) => {
            if (typeof response === 'object' && response.code === '200') {
                return of(createGroupAsync.success(response.message));
            }

            return of(createGroupAsync.failure(response.message));
        }),

        catchError((error) => {
            return of(createGroupAsync.failure(error));
        }),
    );
};

const fetchGroupsEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchGroupsAsync.request)),
        mergeMap((action) => mapFetchGroups(action, dependency)),
    );

const createGroupEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(createGroupAsync.request)),
        mergeMap((action) => mapCreateGroup(action, dependency)),
    );

export {
    fetchGroupsAsync,
    fetchGroupsEpic,
    mapFetchGroups,
    createGroupEpic,
    createGroupAsync,
};
