import { useContext, useEffect } from 'react';

import { PaginationContext } from '../context/context';

type Props = {
    key?: string;
};

const usePagination = <T = any>(props?: Props): T => {
    const ctx: any = useContext(PaginationContext);

    useEffect(() => {
        if (props && props.key) {
            (ctx as any).setCurrentKey(props.key);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ctx;
};

export default usePagination;
