import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Link as RouterLink } from 'react-router-dom';

import { resetReduxStore } from '_actions/auth.actions';
import {
    Avatar,
    Box,
    ButtonBase,
    Menu,
    MenuItem,
    makeStyles,
    RootRef,
} from '@material-ui/core';
import AuthService from 'services/auth-service';

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import { User as UserIcon } from 'react-feather';
import resources from 'utils/resources';
import { stringToColor } from 'utils/helpers';
import { RootState } from 'Types';
import { getAvatarNameFromUser } from 'utils/modules/user-functions';
import { useTranslation } from 'libraries/Translation';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        fontSize: 13,
    },
    avatar_background: {
        backgroundColor: theme.palette.background.default,
        padding: '5px',
        borderRadius: '10px',
    },
    popover: {
        width: 200,
    },
    icon: {
        marginRight: '10px',
    },
}));

const Account: React.FC = () => {
    const [t] = useTranslation('header/settings');

    const classes = useStyles();
    const history = useHistory();
    const ref = useRef<any>(null);
    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state.authReducer.user);

    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = useCallback(async () => {
        try {
            await dispatch(resetReduxStore());
            await AuthService.logout();
            history.push('/login');
        } catch (error) {
            enqueueSnackbar('Unable to logout', {
                variant: 'error',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const name = useMemo(() => getAvatarNameFromUser(user as any), [user]);

    return (
        <>
            <RootRef rootRef={ref}>
                <Box
                    display="flex"
                    alignItems="center"
                    component={ButtonBase}
                    onClick={handleOpen}
                    className={classes.avatar_background}
                >
                    <Avatar
                        alt="entity"
                        className={classes.avatar}
                        src={resources['ic-wekiwi']}
                        variant="square"
                    />

                    <Avatar
                        alt="User"
                        className={classes.avatar}
                        style={{ backgroundColor: stringToColor(name) }}
                        src={
                            user.picture && user.picture !== ''
                                ? user.picture
                                : undefined
                        }
                    >
                        {name}
                    </Avatar>
                </Box>
            </RootRef>

            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                keepMounted
                PaperProps={{ className: classes.popover }}
                getContentAnchorEl={null}
                anchorEl={ref.current}
                open={isOpen}
            >
                <MenuItem component={RouterLink} to="/payment/profile">
                    <UserIcon className={classes.icon} />
                    {t('profile')}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <PowerSettingsNewIcon className={classes.icon} />
                    {t('logout')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default Account;
