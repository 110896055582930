import { createAction, ActionType } from 'typesafe-actions';

import {
  REMOVE_ERROR,
  CHANGE_SECTION,
  REMOVE_MODAL_ERROR,
  CHANGE_CURRENT_MODULE,
} from './actionTypes';
import { ModuleReducer } from 'Types';

const removeError = createAction(REMOVE_ERROR)();

const changeCurrentModule = createAction(CHANGE_CURRENT_MODULE)<{
  module: ModuleReducer;
}>();

const changeSection = createAction(CHANGE_SECTION)<{
  route: string;
  section: string;
}>();

const removeModalError = createAction(REMOVE_MODAL_ERROR)();

export type SettingAction =
  | ActionType<typeof removeError>
  | ActionType<typeof changeSection>
  | ActionType<typeof changeCurrentModule>
  | ActionType<typeof removeModalError>;
export { removeError, changeSection, removeModalError, changeCurrentModule };
