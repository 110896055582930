import parse from './parse';

export default (state, payload, key) => {
    const nextPaginations = { ...state.paginations };
    if (payload.pagination) {
        const parsedPagination = parse(payload.pagination);

        return {
            ...state,
            paginations: {
                ...nextPaginations,
                [key]: {
                    data: parsedPagination,
                    paginationLoading: false,
                },
            },
        };
    }

    if (nextPaginations[key]) {
        delete nextPaginations[key];
    }

    return {
        ...state,
        paginations: nextPaginations,
    };
};
