import Papa from 'papaparse';
import map from 'lodash/map';

import moment from 'moment';

import {
    FinancialPieces,
    FinancialPiece,
    FinancialPieceList,
    FinancialPieceType,
    Invoice,
    Prepayment,
    AdhocPiece,
} from 'types/financial-pieces';

import {
    FinancialPiecesIdListType,
    TContract,
    TPerimeter,
    TBalanceAllocation,
    TManageAllocationMode,
    TAllocationStatus,
} from 'modules/payment';
import { API_FetchFinancialPiecesResponse } from 'api/payment';
import { TDropzoneCallbackItem } from 'Types';
import replace from 'lodash/replace';
import { fetchApiRequestBlob } from 'services/api-service';
import { CustomersState } from '_reducers/modules/payment/search/customer.reducer';
import { TUpdateCustomerThirdPayload } from '_actions/modules/payment';
import { Perimeter } from 'types/perimeter';
import { FinancialInformation } from 'types/financial-information';
import { Customer } from 'types/customer';
import { Individual } from 'types/individual';
import { Address } from 'types/address';
import { Contact } from 'types/contact';
import { Company } from 'types/company';
import { Actor } from 'types/actor';
import { TDocument } from 'modules/customer-care';

const geFinancialPiecesByType = (
    financialPieces: FinancialPieces,
    type: FinancialPieceType,
): FinancialPiece[] => {
    switch (type) {
        case 'in':
            return map(financialPieces.invoices, (invoice: Invoice) => {
                return { ...invoice, type: 'in' };
            });
        case 'pp':
            return map(
                financialPieces.prepayments,
                (prepayment: Prepayment) => {
                    return { ...prepayment, type: 'pp' };
                },
            );

        case 'adhocPieces':
            return map(financialPieces.adhocPieces, (adHoc: AdhocPiece) => {
                return { ...adHoc, type: 'adhocPieces' };
            });
        default:
            return [];
    }
};

const preparePiecesToReducer = <T>(
    pieces: T[],
    type: FinancialPieceType,
): Array<T> => {
    switch (type) {
        case 'in':
            return map(pieces, (invoice: T) => {
                return { ...invoice, type: 'in' };
            });
        case 'pp':
            return map(pieces, (prepayment: T) => {
                return { ...prepayment, type: 'pp' };
            });
        case 'adhocPieces':
            return map(pieces, (adHoc: T) => {
                return { ...adHoc, type: 'adhocPieces' };
            });
        default:
            return [];
    }
};

const getPiecesByTypeFromReducer = (
    state: FinancialPieces,
    type: FinancialPieceType,
): FinancialPiece[] => {
    switch (type) {
        case 'in':
            return state.invoices || [];

        case 'pp':
            return state.prepayments || [];

        case 'adhocPieces':
            return state.adhocPieces || [];

        default:
            return [];
    }
};

const getPieceReducerNameByType = (type: FinancialPieceType) => {
    switch (type) {
        case 'in':
            return 'invoices';
        case 'pp':
            return 'prepayments';

        case 'adhocPieces':
            return 'adhocPieces';

        default:
            return 'invoices';
    }
};

const updateOneThirdOnCustomerReducer = (
    payload: TUpdateCustomerThirdPayload,
    state: CustomersState,
) => {
    const newState = { ...state };

    const customerIndex = newState.customers.findIndex(
        (customer) => customer.id === payload.customerId,
    );
    if (~customerIndex) {
        const perimeterIndex = newState.customers[
            customerIndex
        ].perimeters.findIndex(
            (perimeter) => perimeter.id === payload.perimeterId,
        );
        if (~perimeterIndex) {
            const actorIndex = newState.customers[customerIndex].perimeters[
                perimeterIndex
            ].actors.findIndex((actor) => actor.id === payload.actorId);
            if (
                ~actorIndex &&
                newState.customers[customerIndex].perimeters[perimeterIndex]
                    .actors[actorIndex].third.id === payload.thirdId
            ) {
                newState.customers[customerIndex].perimeters[
                    perimeterIndex
                ].actors[actorIndex].third = {
                    ...newState.customers[customerIndex].perimeters[
                        perimeterIndex
                    ].actors[actorIndex].third,
                    ...payload.newThird,
                };
                return { ...newState };
            }
        }
    }
    return { ...state };
};

const replaceOnePieceOnPiecesReducer = (
    payload: API_FetchFinancialPiecesResponse,
    state: FinancialPieces,
): FinancialPieces => {
    const { adhocPieces, invoices, prepayments } = payload;

    if (invoices && invoices[0]) {
        const newInv = invoices[0];
        newInv.type = 'in';

        return findAndReplace<Invoice>(state, 'invoices', newInv);
    }

    if (prepayments && prepayments[0]) {
        const newPrv = prepayments[0];
        newPrv.type = 'pp';

        return findAndReplace<Prepayment>(state, 'prepayments', newPrv);
    }

    if (adhocPieces && adhocPieces[0]) {
        const newAdh = adhocPieces[0];
        newAdh.type = 'adhocPieces';

        return findAndReplace<AdhocPiece>(state, 'adhocPieces', newAdh);
    }

    return { ...state };
};

const addTypesToPiecesByObject = (
    pieces: API_FetchFinancialPiecesResponse,
) => ({
    invoices: pieces.invoices?.map((p) => ({ ...p, type: 'in' })) || [],
    prepayments: pieces.prepayments?.map((p) => ({ ...p, type: 'pp' })) || [],
    adhocPieces:
        pieces.adhocPieces?.map((p) => ({ ...p, type: 'adhocPieces' })) || [],
});

const findAndReplace = <T>(
    state: FinancialPieces,
    type: any,
    newItem: T,
): FinancialPieces => {
    const curState = { ...state };

    if (curState[type]) {
        const finedIndex = curState[type].findIndex(
            ({ id }) => id === (newItem as any).id,
        );

        if (~finedIndex) {
            curState[type][finedIndex] = newItem;
        }

        return {
            ...state,
            [type]: preparePiecesToReducer<T>(
                curState[type],
                (newItem as any).type,
            ),
        };
    }
    return { ...state };
};

const getNamePiecesManagementByType = (type: FinancialPieceType) => {
    switch (type) {
        case 'in':
            return 'invoiceIdList';
        case 'pp':
            return 'prepaymentIdList';

        case 'adhocPieces':
            return 'adhocPieceIdList';

        default:
            return 'invoiceIdList';
    }
};

const divisionPiecesByType = (pieces: FinancialPieceList) => {
    const prepare: FinancialPiecesIdListType = {
        invoiceIdList: [],
        prepaymentIdList: [],
        adhocPieceIdList: [],
    };

    pieces.forEach((piece: FinancialPiece) => {
        const id = piece.id;
        switch (piece.type as FinancialPieceType) {
            case 'in':
                return prepare.invoiceIdList.push(id);

            case 'pp':
                return prepare.prepaymentIdList.push(id);

            case 'adhocPieces':
                return prepare.adhocPieceIdList.push(id);

            default:
                return;
        }
    });
    return prepare;
};

// UploadCSV
const parseUploadCSVFile = async (file: File): Promise<any> =>
    new Promise((resolve, reject) =>
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                resolve(results);
            },
        }),
    );
//
const parseActionMassFiles = async (
    file: File,
): Promise<FinancialPiecesIdListType> =>
    new Promise((resolve, reject) =>
        Papa.parse(file, {
            header: true,
            skipEmptyLines: 'greedy',
            complete: function (results) {
                const parsed = divisionPiecesByType(
                    results.data as FinancialPieceList,
                );
                resolve(parsed);
            },
        }),
    );

// Document Upload
const removeForBase64 = (type: string, base64: string) => {
    switch (type) {
        case 'application/pdf':
            return replace(base64, 'data:application/pdf;base64,', '');
        case 'image/jpeg':
            return replace(base64, 'data:image/jpeg;base64,', '');
        case 'image/png':
            return replace(base64, 'data:image/png;base64,', '');

        default:
            return base64;
    }
};

// TODO: add payload types
const handleDownloadDocument = async (payload: TDocument) => {
    const path = '/downloadDocument';
    const blob = await fetchApiRequestBlob(payload, path);
    if (blob) {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
    }
};

const parseLogDocumentUpload = async (file): Promise<TDropzoneCallbackItem> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
            resolve({
                size: file.size,
                type: file.type,
                name: file.name,
                base64: removeForBase64(file.type, reader.result as string),
            });
        };
        reader.readAsDataURL(file);
    });
//

const parseCreateAdhocFile = async (file: File): Promise<any[]> =>
    new Promise((resolve, reject) =>
        Papa.parse(file, {
            header: true,
            complete: function (results) {
                const prepareDate = (d) =>
                    moment(d, 'DD MM YYYY').format('YYYY-MM-DD');
                const parsed = results.data.map((e: any) => {
                    const payload: any = {
                        contractNbr: e.contractNbr,
                        externalReference: e.externalReference,
                        pieceReference: e.pieceReference,
                        intermediate: e.intermediate,
                        dueDate: e.dueDate ? prepareDate(e.dueDate) : undefined,
                        date: e.creationDate
                            ? prepareDate(e.creationDate)
                            : undefined,
                        status: e.paymentStatus,
                        periodStart: e.periodStart
                            ? prepareDate(e.periodStart)
                            : undefined,
                        periodEnd: e.periodEnd
                            ? prepareDate(e.periodEnd)
                            : undefined,
                        totalAmount: e.totalAmount,
                        amountToPay: e.amountToPay,
                        amountPaid: e.amountPaid,
                        paymentMode: e.paymentMode,
                        paymentNature: e.paymentNature,
                        initialTransactionId: e.initialTransactionId,
                        initialTransactionStatus: e.initialTransactionStatus,
                        sepaMandate: e.sepaMandate,
                    };
                    if (e.id) {
                        payload.id = e.id;
                    }
                    return payload;
                });

                resolve(parsed);
            },
        }),
    );

const getAllTransactionsIdsBySelectedPieces = (
    selected: string[],
    pieces: FinancialPiece[],
) =>
    //@ts-ignore
    selected.reduce((acc, curr) => {
        const findPiece = pieces.find((p) => p.id === curr);
        if (findPiece) {
            const prepare: Array<string | number> = [];
            findPiece.processusList
                .map((prc) =>
                    prc.transactions
                        ? prc.transactions
                              .filter((tr) => tr.status === 'initialized')
                              .map((tr) => tr.id)
                        : [],
                )
                .forEach((prcTr) => {
                    prcTr.forEach((tr) => prepare.push(tr));
                });

            return [...acc, ...prepare];
        }
        return acc;
    }, []);

const getAllContractsFromPerimeters = (perimeters: TPerimeter[]): TContract[] =>
    perimeters.reduce(
        (acc: TContract[], curr) =>
            curr.contracts ? [...acc, ...(curr.contracts as TContract[])] : acc,
        [],
    );

const findPerimeterByContractId = (perimeters: Perimeter[], id: string) => {
    return perimeters.find(
        (prm) =>
            prm.contracts &&
            prm.contracts.find((ctr) => ctr.id === id) !== undefined,
    );
};
const renderModeOfManageAllocation = ({
    status,
}: TBalanceAllocation): TManageAllocationMode | undefined => {
    switch (status as TAllocationStatus) {
        case 'allocated':
            return 'cancel';

        case 'toAllocate':
            return 'allocate';

        default:
            return undefined;
    }
};

const checkIsRecovery = (nature: string) =>
    nature.toLocaleLowerCase().includes('recovery');

export type PayerData = {
    fullName: string;

    contextInfo: {
        customerId: string;
        perimeterId: string;
        actorId: string;
        thirdId: string;
    };
    id: string;
    type: string;
    individual: Individual;
    address: Address;
    contact: Contact;
    company: Company;

    financialInformation: FinancialInformation;
};

const getPayerActorByContractId = (contractId: string, customer: Customer) => {
    let nextActor: Actor | undefined = undefined;
    let perimeterId = '';

    const { perimeters } = customer;
    for (let i = 0; i < perimeters.length; i++) {
        const perimeter = perimeters[i];
        if (perimeter.contracts.findIndex((c) => c.id === contractId) !== -1) {
            if (perimeter.actors) {
                nextActor = perimeter.actors.find((a) => a.role === 'PAYER');
                perimeterId = perimeter.id;
            }
            break;
        }
    }

    return {
        perimeterId,
        nextActor,
    };
};

const getPayerFullName = (contractId: string, customer: Customer) => {
    const { nextActor } = getPayerActorByContractId(contractId, customer);

    if (nextActor && nextActor.third) {
        const { third } = nextActor;

        if (third?.individual && third?.type === 'individual') {
            const { firstName, lastName } = third.individual;

            return `${lastName} ${firstName}`;
        }

        if (third?.company && third?.company.companyName) {
            return third.company.companyName;
        }
    }

    return '';
};
const getPayerDataByCustomer = (
    contractId: string,
    customer: Customer,
): PayerData | undefined => {
    const { nextActor, perimeterId } = getPayerActorByContractId(
        contractId,
        customer,
    );

    if (nextActor && nextActor.third) {
        const { third, id } = nextActor;

        const prepare: Omit<PayerData, 'fullName'> = {
            contextInfo: {
                customerId: customer.id,
                perimeterId: perimeterId,
                actorId: id,
                thirdId: third.id,
            },
            id: third.id,
            type: third?.type,
            individual: third?.individual,
            address: third?.address,
            contact: third?.contact,
            company: third?.company,
            financialInformation: third?.financialInformation,
        };

        if (third?.individual && third?.type === 'individual') {
            const { firstName, lastName } = third.individual;

            return {
                ...prepare,
                fullName: `${lastName} ${firstName}`,
            };
        }

        if (third?.company && third?.company.companyName) {
            return {
                ...prepare,
                fullName: third.company.companyName,
            };
        }
    }
    return undefined;
};

const buildCustomerAndContract = (customer: Customer) => {
    let i;
    let j;
    let customerContract = {
        customerInfos: {
            customer: Object(),
        },
        contractInfo: {
            id: '',
            energy: '',
            fromDate: '',
            toDate: '',
            contractStatus: '',
        },
    };
    const customersContracts = [customerContract];
    if (customer.perimeters && customer.perimeters.length > 0) {
        for (i = 0; i < customer.perimeters.length; i++) {
            if (
                customer.perimeters[i].contracts &&
                customer.perimeters[i].contracts.length > 0
            ) {
                for (j = 0; j < customer.perimeters[i].contracts.length; j++) {
                    customerContract.customerInfos.customer = customer;
                    customerContract.contractInfo.id =
                        customer.perimeters[i].contracts[j].id;
                    customerContract.contractInfo.energy =
                        customer.perimeters[i].contracts[j].energyType;
                    customerContract.contractInfo.fromDate =
                        customer.perimeters[i].contracts[
                            j
                        ].contractualStartDate;
                    customerContract.contractInfo.toDate =
                        customer.perimeters[i].contracts[j].contractualEndDate;
                    customerContract.contractInfo.contractStatus =
                        customer.perimeters[i].contracts[j].status;
                    customersContracts.push(customerContract);
                }
            }
        }
    }
    //console.log(customersContracts);
    return customersContracts;
};
const getContractInfos = (currContract: any, customer: any) => {
    let nextActors;
    customer.perimeters.forEach((perimeter) => {
        if (
            perimeter.contracts.find(
                (contract) => contract.id === currContract.id,
            )
        ) {
            if (perimeter.actors) {
                nextActors = perimeter.actors.find(
                    (actor) => actor.role === 'PAYER',
                );
                nextActors.perimeterId = perimeter.id;
            }
            return;
        }
    });
    if (nextActors && nextActors.third) {
        const prepare = {
            payerData: {
                contextInfo: {
                    customerId: customer.id,
                    perimeterId: nextActors.perimeterId,
                    actorId: nextActors.id,
                    thirdId: nextActors.third.id,
                },
                id: nextActors.third?.id,
                type: nextActors.third?.type,

                individual: nextActors.third?.individual,
                address: nextActors.third?.address,
                contact: nextActors.third?.contact,
                company: nextActors.third?.company,
                financialInformation: nextActors.third?.financialInformation,
            },
            payer: '',
            financialInformation: nextActors.third?.financialInformation,
        };
        if (
            nextActors.third?.individual &&
            nextActors.third?.type === 'individual'
        ) {
            const { firstName, lastName } = nextActors.third?.individual;
            prepare.payer = `${lastName} ${firstName}`;
        } else if (
            nextActors.third?.company &&
            nextActors.third?.company.companyName
        ) {
            prepare.payer = nextActors.third?.company.companyName;
        }
        return prepare;
    }
    return {
        payerData: {},
        payer: '',
        financialInformation: undefined,
    };
};

export {
    findPerimeterByContractId,
    getContractInfos,
    buildCustomerAndContract,
    geFinancialPiecesByType,
    checkIsRecovery,
    renderModeOfManageAllocation,
    getAllContractsFromPerimeters,
    preparePiecesToReducer,
    getPiecesByTypeFromReducer,
    replaceOnePieceOnPiecesReducer,
    getPieceReducerNameByType,
    getNamePiecesManagementByType,
    parseCreateAdhocFile,
    getAllTransactionsIdsBySelectedPieces,
    parseActionMassFiles,
    parseLogDocumentUpload,
    handleDownloadDocument,
    parseUploadCSVFile,
    updateOneThirdOnCustomerReducer,
    getPayerDataByCustomer,
    getPayerFullName,
    addTypesToPiecesByObject,
};
