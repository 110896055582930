import { User } from 'types/user';

const getAvatarNameFromUser = ({ userName, third }: User): string => {
    if (third && third.individual) {
        const {
            individual: { firstName, lastName },
        } = third;

        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    }

    if (userName) return userName.charAt(0);

    return '';
};

export { getAvatarNameFromUser };
