import { useMemo } from 'react';

import { TMappingType } from 'modules/core';
import { useTranslation } from 'react-i18next';

export default function useMappingData(...arg: TMappingType[]): any[] {
    const [t] = useTranslation('common');
    const allData = t('EnumMapping', {
        returnObjects: true,
    });

    return useMemo(
        () => (arg.length === 0 ? [allData] : arg.map((key) => allData[key])),
        [allData, arg],
    );
}
