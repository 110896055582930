export default {
    URL: process.env.REACT_APP_BACKEND_HOST,
    ApiKey: 'NQ8lVIFswBaoGubnD6nxt5g01PsF44DU5PVdK0sH',
    ENV: process.env.REACT_APP_ENV,

    ACCEPTED_FORMAT_DOCUMENT: 'application/pdf,image/jpeg,image/png',
    ACCEPTED_CSV_MIME: '.csv,text/csv,application/vnd.ms-excel',
    MAX_SIZE_DOCUMENT: 250000, // Bytes

    aws: {
        region: 'eu-west-3',
        apiVersion: '2015-03-31',
        accessKeyId: process.env.REACT_APP_LAMBDA_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_LAMBDA_SECRET_KEY,
    },

    LambdaFunctionNames: {
        synchro_slimpay: process.env.REACT_APP_FUNCTION_SYNCHRO_SLIMPAY,
        synchro_bo: process.env.REACT_APP_FUNCTION_SYNCHRO_BO,
        update_process: process.env.REACT_APP_FUNCTION_UPDATE_PROCESS,
        insert_transaction: process.env.REACT_APP_FUNCTION_INSERT_TRANSACTION,
        lunch_payin_payout: process.env.REACT_APP_FUNCTION_LUNCH_PAYIN_PAYOUT,
    },
};
