import { TSettings, defaultSettings } from '../contexts/SettingsContext';

export const restoreSettings = () => {
    let settings: TSettings = defaultSettings;

    try {
        const storedData = localStorage.getItem('settings');

        if (storedData) {
            settings = JSON.parse(storedData);
        }
    } catch (err) {}

    return settings;
};

export const storeSettings = (settings) => {
    localStorage.setItem('settings', JSON.stringify(settings));
};
