import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { TBuildRoute } from 'Types';
import {
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    ListSubheader,
    makeStyles,
} from '@material-ui/core';

import NavItem from './NavItem';
import Logo from '../../../components/Logo';
import { buildNavConfigByPathname } from 'utils/helpers/nav-bare';
import { useTranslation } from 'libraries/Translation';

function renderNavItems({ items, ...rest }) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) => reduceChildRoutes({ acc, item, ...rest } as any),
                [],
            )}
        </List>
    );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false,
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                key={key}
                info={item.info}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                })}
            </NavItem>,
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={key}
                info={item.info}
                title={item.title}
            />,
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
    },
}));

type NavBarProps = {
    openMobile: boolean;
    routes: TBuildRoute[];
    onMobileClose: () => void;
    isDrawerOpen: boolean;
};

const NavBar: React.FC<NavBarProps> = ({
    openMobile,
    onMobileClose,
    routes,
    isDrawerOpen,
}) => {
    const [t] = useTranslation('payment/user/auth-schema');
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }

        // eslint-disable-next-line
    }, [location.pathname]);

    const navConfig = buildNavConfigByPathname(routes, location.pathname, t);
    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        <RouterLink to="/">
                            <Logo />
                        </RouterLink>
                    </Box>
                </Hidden>

                <Divider />
                <Box p={2}>
                    {navConfig.map((config) => (
                        <List
                            key={config.subheader}
                            subheader={
                                <ListSubheader
                                    style={{ fontWeight: 600 }}
                                    disableGutters
                                >
                                    {config.subheader}
                                </ListSubheader>
                            }
                        >
                            {renderNavItems({
                                items: config.items,
                                pathname: location.pathname,
                            })}
                        </List>
                    ))}
                </Box>
                <Divider />
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open={isDrawerOpen}
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

export default NavBar;
