import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import Lottie from 'react-lottie';

import animationData from '../assets/loader.json';

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        padding: theme.spacing(3),
    },
    spinner: {
        width: 180,
        height: 180,
    },
    spinnerBackground: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 10000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
};

export const LoadingSpinner: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.spinnerBackground}>
            <div className={classes.spinner}>
                <Lottie
                    options={defaultOptions}
                    height={180}
                    width={180}
                    isStopped={false}
                />
            </div>
        </div>
    );
};

const LoadingScreen = () => {
    const classes = useStyles();

    useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    }, []);

    return (
        <div className={classes.root}>
            <Box width={400}>
                <LinearProgress />
            </Box>
        </div>
    );
};

type LoadingScreenWrapperProps = {
    loading: boolean;
};

const LoadingScreenWrapper: React.FC<LoadingScreenWrapperProps> = ({
    loading,
    children,
}) => {
    if (loading) {
        return <LoadingScreen />;
    }
    return <>{children}</>;
};

export default LoadingScreen;

export { LoadingScreenWrapper };
