import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as groupActions from '_actions/modules/payment/group.actions';
import * as actions from '_actions/modules/payment/user.actions';
import findIndex from 'lodash/findIndex';

import { User } from 'types/user';

export type CustomersState = Readonly<{
    groups: string[];
    users: User[];
    loading: boolean;
}>;

const initialState = {
    groups: [],
    users: [],
    loading: false,
};

type Actions = actions.UserAction | groupActions.GroupAction;

const userReducer: Reducer<CustomersState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(groupActions.fetchGroupsAsync.success): {
            return {
                ...state,
                groups: action.payload.groupList,
            };
        }

        case getType(actions.searchUserGroupAsync.request): {
            return {
                ...state,
                loading: true,
            };
        }

        case getType(actions.searchUserGroupAsync.success): {
            return {
                ...state,
                users: action.payload.userList,
                loading: false,
            };
        }

        case getType(actions.searchUserGroupAsync.failure): {
            return {
                ...state,
                users: [],
                loading: false,
            };
        }

        case getType(actions.manageUserAsync.success): {
            if (action.payload.updatedUser) {
                const { updatedUser } = action.payload;
                const newUsersState = [...state.users];
                const index = findIndex(newUsersState, {
                    userName: updatedUser.userName,
                });

                newUsersState.splice(index, 1, updatedUser);

                return {
                    ...state,
                    users: [...newUsersState],
                };
            }
            return { ...state };
        }

        default:
            return { ...state };
    }
};

export default userReducer;
