import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as actions from '_actions/modules/payment/monitoring.actions';
import { Monitoring, MonitoringSection } from 'types/monitoring';

export type MonitoringErrors = Partial<Record<MonitoringSection, any>>;

export type MonitoringState = Readonly<{
    monitorings: Monitoring[];
    synchronizationMonitorings: Monitoring[];
    transactionalReleseMonitorings: Monitoring[];

    errors: MonitoringErrors;

    loading: boolean;
}>;

const initialState = {
    loading: false,
    errors: {},
    monitorings: [],
    synchronizationMonitorings: [],
    transactionalReleseMonitorings: [],
};

const getKeyBySection = (currentSection: MonitoringSection) => {
    switch (currentSection) {
        case 'MONITORING':
            return 'monitorings';

        case 'SYNCHRONIZATION':
            return 'synchronizationMonitorings';

        case 'TRANSACTIONAL_RELEASE':
            return 'transactionalReleseMonitorings';

        default:
            return 'monitoring';
    }
};

type Actions = actions.MonitoringAction;

const monitoringReducer: Reducer<MonitoringState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchMonitoringAsync.request): {
            return { ...state, loading: true };
        }

        case getType(actions.fetchMonitoringAsync.success): {
            const { currentSection, monitoring } = action.payload;

            return {
                ...state,
                loading: false,
                errors: {
                    ...state.errors,
                    [currentSection]: undefined,
                },
                [getKeyBySection(currentSection)]: monitoring,
            };
        }

        case getType(actions.fetchMonitoringAsync.failure): {
            const { currentSection, ...error } = action.payload;

            return {
                ...state,
                [getKeyBySection(currentSection)]: [],
                loading: false,
                errors: {
                    ...state.errors,
                    [currentSection]: error,
                },
            };
        }

        case getType(actions.resetMonitoringList): {
            return {
                ...state,
                monitorings: [],

                errors: {
                    ...state.errors,
                    MONITORING: undefined,
                },
            };
        }

        case getType(actions.resetSynchronizationMonitoringList): {
            return {
                ...state,
                synchronizationMonitorings: [],

                errors: {
                    ...state.errors,
                    SYNCHRONIZATION: undefined,
                },
            };
        }

        case getType(actions.resetTransactionalReleaseMonitoringList): {
            return {
                ...state,
                transactionalReleseMonitorings: [],

                errors: {
                    ...state.errors,
                    TRANSACTIONAL_RELEASE: undefined,
                },
            };
        }

        default:
            return { ...state };
    }
};

export default monitoringReducer;
