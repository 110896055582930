import { TInvoice, TPrepayment, TAdhoc } from 'modules/payment';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as actions from '_actions/modules/payment/pieces.actions';
import * as transactionActions from '_actions/modules/payment/transaction.actions';
import { FinancialPieces } from 'types/financial-pieces';
import { redirectByTypePiece } from 'utils/helpers/link';
import {
    manageRequestWithPagination,
    updateDataByPagination,
} from 'utils/helpers/pagination';
import {
    preparePiecesToReducer,
    replaceOnePieceOnPiecesReducer,
    getPieceReducerNameByType,
} from 'utils/modules/payment-functions';

export type PiecesState = Readonly<
    {
        loading: boolean;
        error?: any;
    } & FinancialPieces
>;

type Actions = actions.PiecesAction | transactionActions.TransactionAction;

const initialState = {
    invoices: undefined,
    prepayments: undefined,
    adhocPieces: undefined,

    loading: false,
};

const defaultErrorAndLoadingState = {
    loading: false,
    error: undefined,
};

const piecesReducer: Reducer<PiecesState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchFinancialPiecesAsync.request): {
            return manageRequestWithPagination(state, action.payload);
        }
        case getType(actions.fetchFinancialPiecesAsync.success): {
            redirectByTypePiece(action.payload);

            if (action.payload.invoices) {
                const invoices = updateDataByPagination<any>({
                    pagination: (action.payload as any).pagination,
                    nextData: action.payload.invoices,
                    stateData: state.invoices || [],
                });
                return {
                    ...state,
                    ...defaultErrorAndLoadingState,
                    invoices: preparePiecesToReducer<TInvoice>(invoices, 'in'),
                };
            }

            if (action.payload.prepayments) {
                const prepayments = updateDataByPagination<any>({
                    pagination: (action.payload as any).pagination,
                    nextData: action.payload.prepayments,
                    stateData: state.prepayments || [],
                });
                return {
                    ...state,
                    ...defaultErrorAndLoadingState,
                    prepayments: preparePiecesToReducer<TPrepayment>(
                        prepayments,
                        'pp',
                    ),
                };
            }
            if (action.payload.adhocPieces) {
                const adhocPieces = updateDataByPagination<any>({
                    pagination: (action.payload as any).pagination,
                    nextData: action.payload.adhocPieces,
                    stateData: state.adhocPieces || [],
                });
                return {
                    ...state,
                    ...defaultErrorAndLoadingState,
                    adhocPieces: preparePiecesToReducer<TAdhoc>(
                        adhocPieces,
                        'adhocPieces',
                    ),
                };
            }

            return { ...state, loading: false };
        }

        case getType(actions.fetchFinancialPiecesAsync.failure): {
            return { ...state, error: action.payload, loading: false };
        }

        case getType(actions.fetchOnePieceAsync.success):
            return {
                ...state,
                ...defaultErrorAndLoadingState,
                ...replaceOnePieceOnPiecesReducer(action.payload, state),
            };

        case getType(actions.resetPiecesList):
            return {
                ...state,
                [getPieceReducerNameByType(action.payload)]: undefined,
                ...defaultErrorAndLoadingState,
            };

        default:
            return { ...state };
    }
};

export default piecesReducer;
