import { createAdhocPaymentEpic } from './adhoc.actions';
import { fetchBalanceEpic } from './balance.actions';
import {
    fetchCustomerContractEpic,
    fetchCustomerFinancialPiecesEpic,
    fetchCustomerOneFinancialPiecesEpic,
} from './customer.actions';
import { fetchGroupsEpic, createGroupEpic } from './group.actions';

import { searchEntitiesEpic } from './entity.actions';
import {
    bankTransactionEpic,
    createLetteringProposalEpic,
} from './lettering.actions';
import { monitoringEpic, monitoringActionsEpic } from './monitoring.actions';
import { fetchFinancialPiecesEpic, fetchOnePieceEpic } from './pieces.actions';
import { activitiesEpic } from './revival.actions';
import { searchUserGroupEpic, manageUserEpic } from './user.actions';

export default [
    fetchOnePieceEpic,
    fetchFinancialPiecesEpic,
    createAdhocPaymentEpic,
    fetchCustomerContractEpic,
    fetchCustomerFinancialPiecesEpic,
    fetchCustomerOneFinancialPiecesEpic,
    fetchBalanceEpic,
    monitoringEpic,
    monitoringActionsEpic,
    fetchGroupsEpic,
    createGroupEpic,
    searchUserGroupEpic,
    manageUserEpic,
    searchEntitiesEpic,
    bankTransactionEpic,
    activitiesEpic,
    createLetteringProposalEpic,
];
