import { CallbacksProps } from 'lib/pagination';
import {
    buildPaginationReducer,
    getType,
    buildActionsByKey,
} from 'libraries/Pagination';

import {
    fetchFinancialPiecesAsync,
    fetchCustomerContractAsync,
    resetPiecesList,
    resetCustomers,
} from '_actions/modules/payment';

const financialPiecesActions = {
    [getType(fetchFinancialPiecesAsync.request)]: (state, action, fn) => {
        const { payload } = action;
        const key = `FINANCIAL_PIECES_${payload.piecesType}`;
        return fn(state, payload, key);
    },

    [getType(fetchFinancialPiecesAsync.success)]: (state, action, fn) => {
        const { payload } = action;
        const type = (() => {
            if (payload.invoices) return 'in';
            if (payload.prepayments) return 'pp';
            if (payload.adhocPieces) return 'adhocPieces';
            return '';
        })();
        const key = `FINANCIAL_PIECES_${type}`;
        return fn(state, payload, key);
    },

    [getType(resetPiecesList)]: (state, action, fn) => {
        const { payload } = action;
        const key = `FINANCIAL_PIECES_${payload}`;
        return fn(state, payload, key);
    },
};

export default buildPaginationReducer({
    ...financialPiecesActions,

    ...buildActionsByKey('ADVANCED', [
        getType(fetchCustomerContractAsync.request),
        getType(fetchCustomerContractAsync.success),
        getType(resetCustomers),
    ]),
} as CallbacksProps);
