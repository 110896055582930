/**
 * @author Ivan Naluzhnyi
 * @email [ivan.naluzhnyi@gmail.com]
 * @create date 2019-09-16 16:44:55
 * @modify date 2019-09-16 16:48:44
 * @desc [Key-System | Uppli]
 */

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { useSnackbar } from 'notistack';
import { RootState } from 'Types';

import { removeModalError } from '../_actions/setting.actions';
import { getMessageSnackbar } from '../utils/helpers';
import { EManageUserMode } from '../constants/enums';
import Routes from '../Routes';

const Main: React.FC = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const { authorizationsSchema, modalResponse, lastPayload } = useSelector(
        (state: RootState) => ({
            authorizationsSchema: state.authReducer.authorizationsSchema,
            modalResponse: state.settingReducer.modalResponse,
            lastPayload: state.settingReducer.lastPayload,
        }),
    );

    useEffect(() => {
        if (modalResponse.typeResponse === 'SUCCESS') {
            enqueueSnackbar(
                getMessageSnackbar(
                    modalResponse,
                    'Votre demande a bien été traitée',
                ),

                {
                    variant: 'success',
                },
            );

            if (modalResponse.actionType === 'CREATE_USER_SUCCESS') {
                history.push(`/profile/${lastPayload.username}/edit`);
            }

            if (
                modalResponse.actionType === 'MANAGE_USER_SUCCESS' &&
                lastPayload.mode === EManageUserMode.DELETE
            ) {
                history.push('/users');
            }
        }

        const errorIsTable =
            modalResponse.error &&
            !isEmpty((modalResponse.error as any).errors);

        if (modalResponse.typeResponse === 'FAILURE' && !errorIsTable) {
            enqueueSnackbar(
                modalResponse.error
                    ? (modalResponse.error as any).description
                    : 'Une erreur est survenue',
                {
                    variant: 'error',
                },
            );
        }
        return () => {
            if (
                modalResponse.error &&
                !isEmpty((modalResponse.error as any).errors)
            )
                dispatch(removeModalError());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalResponse.typeResponse]);

    return (
        <>
            <Routes authorizationScheme={authorizationsSchema} />
        </>
    );
};

export default Main;
