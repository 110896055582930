import React, { useState } from 'react';
import { defaultPaginationReducer } from '../constants';

import { PaginationProviderProps } from 'lib/pagination';

import { PaginationContext } from './context';

export type RequestState =
    | {
          fn: (payload: any) => void;
          payload: any;
      }
    | undefined;

export const PaginationProvider: React.FC<PaginationProviderProps> = ({
    children,
    pagination: paginationProp = defaultPaginationReducer,
}) => {
    const [requestState, setRequestState] = useState<RequestState>(undefined);
    const { data: pagination, paginationLoading } = paginationProp;

    const request = () => {
        if (requestState) {
            const { fn, payload } = requestState;

            fn({
                ...payload,
                pagination,
                paginationLoading: true,
            });
        }
    };

    const setRequest = (fn: (payload: any) => void, payload: any) => {
        setRequestState({ fn, payload });
    };

    return (
        <PaginationContext.Provider
            value={{
                pagination,
                request,
                setRequest,
                enabled: true,
                paginationLoading,
            }}
        >
            {children}
        </PaginationContext.Provider>
    );
};
