import { TModulesId, TModulesPath } from 'modules/core';
import resources from 'utils/resources';

const getIconFromRootName = (moduleId: TModulesId) => {
    switch (moduleId) {
        case 'paymentManagement':
            return resources['ic-module-payment'];

        case 'customerCare':
            return resources['ic-module-customer-care'];

        default:
            return '';
    }
};

const getModuleListFromAuthSchema = (schema) => {
    return schema.map(({ id, label, moduleId }) => ({
        id,
        label,
        moduleId,
    }));
};

const isCurrentByModuleId = (
    pathname: string,
    moduleId: TModulesId,
): boolean => {
    const splited = pathname.split('/');

    if (splited[0] === '') splited.shift();
    const preparePath = `/${splited[0]}`;

    switch (moduleId) {
        case 'paymentManagement':
            return preparePath === ('/payment' as TModulesPath);

        case 'customerCare':
            return preparePath === ('/customer-care' as TModulesPath);

        default:
            return false;
    }
};

const getPathByModuleId = (moduleId: TModulesId): TModulesPath => {
    switch (moduleId) {
        case 'paymentManagement':
            return '/payment';

        case 'customerCare':
            return '/customer-care';

        default:
            return '/payment';
    }
};

const getModuleIdByPath = (path: TModulesPath | string): TModulesId => {
    switch (path) {
        case '/payment':
            return 'paymentManagement';

        case '/customer-care':
            return 'customerCare';

        default:
            return 'paymentManagement';
    }
};

const isCurrentByPath = (pathname: string, path: TModulesPath) => {
    const splited = pathname.split('/');

    if (splited[0] === '') splited.shift();
    const preparePath = `/${splited[0]}`;

    return preparePath === path;
};

const getCurrentPath = (pathname: string): TModulesPath => {
    const [, module] = pathname.split('/');

    switch (`/${module}`) {
        case '/payment':
            return '/payment';

        case '/customer-care':
            return '/customer-care';

        default:
            return '/payment';
    }
};

export {
    getModuleListFromAuthSchema,
    getIconFromRootName,
    isCurrentByModuleId,
    isCurrentByPath,
    getPathByModuleId,
    getModuleIdByPath,
    getCurrentPath,
};
