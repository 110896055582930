import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as paymentActions from '_actions/modules/payment';
import {
    ReducerError,
    TRouteSection,
    TModalResponse,
    ModuleReducer,
} from 'Types';

import * as authActions from '../_actions/auth.actions';
import * as customersActions from '../_actions/modules/payment/customer.actions';
import * as groupActions from '../_actions/modules/payment/group.actions';
import * as userActions from '../_actions/modules/payment/user.actions';
import * as actions from '../_actions/setting.actions';
import { getModuleListFromAuthSchema } from '../utils/helpers/modules';

export type SettingState = Readonly<{
    error?: ReducerError;
    loading: boolean;
    routeSection: TRouteSection;
    modalResponse: TModalResponse;
    lastPayload: any;
    modules: ModuleReducer[];
}>;

const initialState: SettingState = {
    error: undefined,
    loading: false,
    routeSection: {
        home: 'ADVANCED',
        lettering: 'RESOLVED',
        monitoring: 'MONITORING',
    },
    lastPayload: undefined,
    modalResponse: { error: undefined, typeResponse: 'REQUEST' },
    modules: [],
};

type Actions =
    | actions.SettingAction
    | authActions.AuthAction
    | groupActions.GroupAction
    | userActions.UserAction
    | customersActions.CustomersAction
    | paymentActions.PaymentAction;

const settingReducer: Reducer<SettingState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.changeSection): {
            const { route, section } = action.payload;
            return {
                ...state,
                routeSection: { ...state.routeSection, [route]: section },
            };
        }
        case getType(actions.removeError):
            return {
                ...state,
                error: undefined,
            };

        case getType(actions.removeModalError):
            return {
                ...state,
                modalResponse: {
                    error: undefined,
                    typeResponse: 'REQUEST',
                },
            };

        case getType(authActions.fetchLoginAsync.success): {
            const generatedModules = getModuleListFromAuthSchema(
                action.payload.authorizationsScheme,
            );
            return {
                ...state,
                loading: false,
                error: undefined,
                currentModule: generatedModules[0],
                modules: generatedModules,
            };
        }
        case getType(groupActions.createGroupAsync.request):
        case getType(userActions.searchUserGroupAsync.request):
        case getType(userActions.createUserAsync.request):
        case getType(userActions.manageUserAsync.request):
        case getType(authActions.fetchLoginAsync.request):
        case getType(authActions.resetPasswordAsync.request):
        case getType(authActions.changePasswordAsync.request):
        case getType(paymentActions.fetchOnePieceAsync.request):
        case getType(customersActions.fetchCustomerFPAsync.request): {
            return {
                ...state,
                loading: true,
                lastPayload: action.payload,
            };
        }

        case getType(groupActions.fetchGroupsAsync.success):
        case getType(userActions.searchUserGroupAsync.success):
        case getType(authActions.resetPasswordAsync.success):
        case getType(paymentActions.fetchOnePieceAsync.success):
        case getType(customersActions.fetchCustomerFPAsync.success): {
            return {
                ...state,
                loading: false,
                error: undefined,
            };
        }

        case getType(userActions.searchUserGroupAsync.failure):
        case getType(authActions.fetchLoginAsync.failure):
        case getType(authActions.resetPasswordAsync.failure):
        case getType(paymentActions.fetchOnePieceAsync.failure):
        case getType(customersActions.fetchCustomerFPAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case getType(groupActions.createGroupAsync.success):
        case getType(userActions.createUserAsync.success):
        case getType(userActions.manageUserAsync.success):
        case getType(authActions.changePasswordAsync.success): {
            return {
                ...state,
                loading: false,
                modalResponse: {
                    typeResponse: 'SUCCESS',
                    error: undefined,
                    message: action.payload,
                    actionType: action.type,
                },
            };
        }

        case getType(userActions.createUserAsync.failure):
        case getType(userActions.manageUserAsync.failure):
        case getType(groupActions.createGroupAsync.failure):
        case getType(authActions.changePasswordAsync.failure): {
            return {
                ...state,
                loading: false,
                modalResponse: {
                    typeResponse: 'FAILURE',
                    error: action.payload,
                    actionType: action.type,
                },
            };
        }

        default:
            return { ...state };
    }
};

export default settingReducer;
