import { EActivityCategory } from 'constants/enums';

import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createAction,
} from 'typesafe-actions';

import {
    API_FetchActivitiesRequest,
    API_FetchActivitiesResponse,
} from 'api/payment';
import { RootAction, RootState, Services, ReducerError } from 'Types';

import {
    FETCH_ACTIVITIES_REQUEST,
    FETCH_ACTIVITIES_FAILURE,
    FETCH_ACTIVITIES_SUCCESS,
} from './actionTypes';
import { RESET_ACTIVITY_LIST } from './actionTypes';

const resetActivityList = createAction(RESET_ACTIVITY_LIST)();

const fetchActivitiesAsync = createAsyncAction(
    FETCH_ACTIVITIES_REQUEST,
    FETCH_ACTIVITIES_SUCCESS,
    FETCH_ACTIVITIES_FAILURE,
)<API_FetchActivitiesRequest, API_FetchActivitiesResponse, ReducerError>();

export type RevivalAction =
    | ActionType<typeof fetchActivitiesAsync>
    | ActionType<typeof resetActivityList>;

const preparePayloadActivities = (payload: API_FetchActivitiesRequest) => {
    return { ...payload, category: EActivityCategory.COLLECTION };
};

const mapGetActivities = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadActivities(action.payload);
    return apiRequest<API_FetchActivitiesResponse>({
        path: '/searchActivities',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: API_FetchActivitiesResponse) => {
            return of(fetchActivitiesAsync.success(response));
        }),

        catchError((error) => {
            return of(fetchActivitiesAsync.failure(error));
        }),
    );
};

const mapGetActivitiesCustomer = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadActivities(action.payload);
    return apiRequest<API_FetchActivitiesResponse>({
        path: '/searchActivities',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: API_FetchActivitiesResponse) => {
            return of(response);
        }),

        catchError((error) => {
            return of(error);
        }),
    );
};

const activitiesEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchActivitiesAsync.request)),
        mergeMap((action) => mapGetActivities(action, dependency)),
    );

export {
    activitiesEpic,
    fetchActivitiesAsync,
    mapGetActivities,
    mapGetActivitiesCustomer,
    resetActivityList,
};
