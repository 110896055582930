import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createAction,
} from 'typesafe-actions';

import {
    FETCH_MONITORING_REQUEST,
    FETCH_MONITORING_SUCCESS,
    FETCH_MONITORING_FAILURE,
    MONITORING_ACTIONS_REQUEST,
    MONITORING_ACTIONS_FAILURE,
    MONITORING_ACTIONS_SUCCESS,
    RESET_TRANSACTIONAL_RELEASE_MONITORING_LIST,
    RESET_MONITORING_LIST,
    RESET_SYNCHRONIZATION_MONITORING_LIST,
} from './actionTypes';

import { RootAction, RootState, Services, TError } from 'Types';

import { ActionAsync, MonitoringAsync } from './types/monitoring.actions.types';

const resetMonitoringList = createAction(RESET_MONITORING_LIST)();
const resetSynchronizationMonitoringList = createAction(
    RESET_SYNCHRONIZATION_MONITORING_LIST,
)();
const resetTransactionalReleaseMonitoringList = createAction(
    RESET_TRANSACTIONAL_RELEASE_MONITORING_LIST,
)();

const fetchMonitoringAsync = createAsyncAction(
    FETCH_MONITORING_REQUEST,
    FETCH_MONITORING_SUCCESS,
    FETCH_MONITORING_FAILURE,
)<MonitoringAsync.Payload, MonitoringAsync.Success, any>();

const monitoringActionsAsync = createAsyncAction(
    MONITORING_ACTIONS_REQUEST,
    MONITORING_ACTIONS_SUCCESS,
    MONITORING_ACTIONS_FAILURE,
)<ActionAsync.Payload, API.ProcessingLauncher.Response, TError | TError[]>();

export type MonitoringAction =
    | ActionType<typeof fetchMonitoringAsync>
    | ActionType<typeof monitoringActionsAsync>
    | ActionType<typeof resetMonitoringList>
    | ActionType<typeof resetSynchronizationMonitoringList>
    | ActionType<typeof resetTransactionalReleaseMonitoringList>;

const preparePayload = ({
    Id,
    action,
    date,
    fileName,
    idObject,
    object,
    status,
}: API.Monitoring.Request) => {
    return {
        Id,
        object,
        idObject,
        fileName,
        action,
        status,
        date,
    };
};

const mapGetMonitoring = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayload(action.payload);

    return apiRequest<API.Monitoring.Response>({
        path: '/monitoring',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(({ monitoring }: API.Monitoring.Response) => {
            return of(
                fetchMonitoringAsync.success({
                    currentSection: action.payload.currentSection,
                    monitoring,
                }),
            );
        }),

        catchError((error) => {
            return of(
                fetchMonitoringAsync.failure({
                    ...error,
                    currentSection: action.payload.currentSection,
                }),
            );
        }),
    );
};

const mapMonitoringActions = (action: RootAction, { apiRequest }: Services) => {
    const { selectedAction } = action.payload;

    return apiRequest<any>({
        path: '/processingLauncher',
        method: 'post',
        body: {
            processingName: selectedAction,
        },
    }).pipe(
        mergeMap((response: any) => {
            if (
                response.statusCode &&
                (response.statusCode === 400 || response.statusCode === 500)
            ) {
                return of(
                    monitoringActionsAsync.failure({
                        code: '',
                        message:
                            'Le traitement a été lancé, veuillez suivre le statut exécution via le monitring',
                    } as TError),
                );
            }
            return of(monitoringActionsAsync.success({}));
        }),

        catchError((error) => {
            return of(monitoringActionsAsync.failure(error));
        }),
    );
};

const monitoringEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchMonitoringAsync.request)),
        mergeMap((action) => mapGetMonitoring(action, dependency)),
    );

const monitoringActionsEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(monitoringActionsAsync.request)),
        mergeMap((action) => mapMonitoringActions(action, dependency)),
    );
export {
    mapGetMonitoring,
    fetchMonitoringAsync,
    monitoringEpic,
    monitoringActionsEpic,
    monitoringActionsAsync,
    mapMonitoringActions,
    resetMonitoringList,
    resetSynchronizationMonitoringList,
    resetTransactionalReleaseMonitoringList,
};
