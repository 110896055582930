import get from 'lodash/get';
import { Epic } from 'redux-observable';
import { of, throwError, forkJoin } from 'rxjs';
import { mergeMap, catchError, filter, switchMap } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createAction,
} from 'typesafe-actions';

import {
    API_CustomerContractRequest,
    API_CustomerContractResponse,
    API_FetchFinancialPiecesRequest,
    API_FetchFinancialPiecesResponse,
} from 'api/payment';
import { RootAction, RootState, Services } from 'Types';
import { addPaginationToPayload } from 'utils/helpers/pagination';

import {
    FETCH_CUSTOMER_CONTRACT_FAILURE,
    FETCH_CUSTOMER_CONTRACT_REQUEST,
    FETCH_CUSTOMER_CONTRACT_SUCCESS,
    FETCH_CUSTOMER_FINANCIAL_PIECES_REQUEST,
    FETCH_CUSTOMER_FINANCIAL_PIECES_SUCCESS,
    FETCH_CUSTOMER_FINANCIAL_PIECES_FAILURE,
    FETCH_CUSTOMER_ONE_FINANCIAL_PIECES_REQUEST,
    FETCH_CUSTOMER_ONE_FINANCIAL_PIECES_SUCCESS,
    FETCH_CUSTOMER_ONE_FINANCIAL_PIECES_FAILURE,
    INIT_CUSTOMER_LIST,
    UPDATE_CUSTOMER_THIRD,
} from './actionTypes';
import { fetchBalanceAsync, mapGetBalance } from './balance.actions';
import { mapGetFinancialPieces, mapGetOnePiece } from './pieces.actions';

const resetCustomers = createAction(INIT_CUSTOMER_LIST)();

export type TUpdateCustomerThirdPayload = {
    newThird: any;
    customerId: string;
    perimeterId: string;
    actorId: string;
    thirdId: string;
};

const updateCustomerThird = createAction(UPDATE_CUSTOMER_THIRD)<
    TUpdateCustomerThirdPayload
>();

const fetchCustomerContractAsync = createAsyncAction(
    FETCH_CUSTOMER_CONTRACT_REQUEST,
    FETCH_CUSTOMER_CONTRACT_SUCCESS,
    FETCH_CUSTOMER_CONTRACT_FAILURE,
)<API_CustomerContractRequest, API_CustomerContractResponse, any>();

const fetchCustomerFPAsync = createAsyncAction(
    FETCH_CUSTOMER_FINANCIAL_PIECES_REQUEST,
    FETCH_CUSTOMER_FINANCIAL_PIECES_SUCCESS,
    FETCH_CUSTOMER_FINANCIAL_PIECES_FAILURE,
)<API_FetchFinancialPiecesRequest, API_FetchFinancialPiecesResponse, any>();

const fetchCustomerOneFPAsync = createAsyncAction(
    FETCH_CUSTOMER_ONE_FINANCIAL_PIECES_REQUEST,
    FETCH_CUSTOMER_ONE_FINANCIAL_PIECES_SUCCESS,
    FETCH_CUSTOMER_ONE_FINANCIAL_PIECES_FAILURE,
)<API_FetchFinancialPiecesRequest, API_FetchFinancialPiecesResponse, any>();

export type CustomersAction =
    | ActionType<typeof fetchCustomerContractAsync>
    | ActionType<typeof fetchCustomerFPAsync>
    | ActionType<typeof fetchCustomerOneFPAsync>
    | ActionType<typeof resetCustomers>
    | ActionType<typeof updateCustomerThird>;

const preparePayloadCustomerContract = (
    payload: API_CustomerContractRequest,
): API_CustomerContractRequest => {
    return addPaginationToPayload({ ...payload });
};

const mapGetCustomerContract = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadCustomerContract(action.payload);
    return apiRequest<API_CustomerContractResponse>({
        path: '/customerAndContract',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: API_CustomerContractResponse) => {
            if (response) {
                return of(response);
            }
            return throwError(response);
        }),

        catchError((error) => {
            return of(error);
        }),
    );
};

const fetchCustomerContractEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(fetchCustomerContractAsync.request)),
        switchMap(
            (action: RootAction) => mapGetCustomerContract(action, dependency),
            (action: RootAction, r: any) => [action, r],
        ),

        switchMap(([action, customerContractResponse]) => {
            if (get(customerContractResponse, 'errors')) {
                return of(
                    fetchCustomerContractAsync.failure(
                        customerContractResponse,
                    ),
                );
            }

            return of(
                fetchCustomerContractAsync.success(customerContractResponse),
            );
        }),

        catchError((error) => {
            return of(fetchCustomerContractAsync.failure(error));
        }),
    );

const fetchCustomerFinancialPiecesEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(fetchCustomerFPAsync.request)),
        switchMap(
            (action: RootAction) =>
                mapGetFinancialPieces(action, dependency, true),
            (action: RootAction, r: any) => [action, r],
        ),

        switchMap(([action, financialPiecesResponse]) => {
            return forkJoin([
                of(financialPiecesResponse),
                mapGetBalance(action, dependency),
            ]);
        }),

        switchMap(([financialPiecesResponse, balanceResponse]) => {
            const checkIsError = {
                financialPieces: !!get(financialPiecesResponse, 'error'),
                balance: !!get(balanceResponse, 'error') || !balanceResponse,
            };

            return of(
                fetchCustomerFPAsync[
                    checkIsError.financialPieces ? 'failure' : 'success'
                ](financialPiecesResponse as any),

                fetchBalanceAsync[checkIsError.balance ? 'failure' : 'success'](
                    balanceResponse as any,
                ),
            );
        }),

        catchError((error) => {
            return of(fetchCustomerFPAsync.failure(error));
        }),
    );

const fetchCustomerOneFinancialPiecesEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(fetchCustomerOneFPAsync.request)),
        switchMap(
            (action: RootAction) => mapGetOnePiece(action, dependency),
            (action: RootAction, r: any) => [action, r],
        ),

        switchMap(([action, financialPiecesResponse]) => {
            return of(fetchCustomerOneFPAsync.success(financialPiecesResponse));
        }),

        catchError((error) => {
            return of(fetchCustomerOneFPAsync.failure(error));
        }),
    );

export {
    mapGetCustomerContract,
    fetchCustomerFinancialPiecesEpic,
    fetchCustomerContractAsync,
    fetchCustomerContractEpic,
    fetchCustomerFPAsync,
    resetCustomers,
    fetchCustomerOneFinancialPiecesEpic,
    fetchCustomerOneFPAsync,
    updateCustomerThird,
};
