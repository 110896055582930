import apiRequest from './api-service';
import * as localStorage from './local-storage-service';

const logger = { log: console.log };

export default {
    localStorage,
    logger,
    apiRequest,
};
