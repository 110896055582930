/**
 * @author Ivan Naluzhnyi
 * @email [ivan.naluzhnyi@gmail.com]
 * @create date 2019-08-05 16:44:55
 * @modify date 2019-10-10 16:38:36
 * @desc [Key-System | Uppli]
 */

import React, { Suspense } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

import MomentUtils from '@date-io/moment';
import { ThemeProvider, makeStyles, createStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { ConfirmationModalProvider } from './contexts/ConfirmationModalContext';
import { SettingsProvider } from './contexts/SettingsContext';

import './index.css';
import useSettings from './hooks/useSettings';
import i18n from './services/i18n';
import * as serviceWorker from './serviceWorker';
import { version } from '../package.json';
import store from './store';
import { createTheme } from './theme';
import { restoreSettings } from './utils/settings';
import ConnectedMain from './views/Main';

import LoadingScreen from 'components/LoadingScreen';

const useStyles = makeStyles(() =>
    createStyles({
        '@global': {
            '*': {
                boxSizing: 'border-box',
                margin: 0,
                padding: 0,
            },
            html: {
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'grayscale',
                height: '100%',
                width: '100%',
            },
            body: {
                height: '100%',
                width: '100%',
            },
            '#root': {
                height: '100%',
                width: '100%',
                position: 'absolute',
            },
        },
    }),
);

const App = () => {
    useStyles();
    const { settings } = useSettings();
    return (
        <ThemeProvider theme={createTheme(settings)}>
            <I18nextProvider i18n={i18n}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <SnackbarProvider>
                        <Suspense fallback={<LoadingScreen />}>
                            <ConfirmationModalProvider>
                                <Router>
                                    <Switch>
                                        <ConnectedMain />
                                    </Switch>
                                </Router>
                            </ConfirmationModalProvider>
                        </Suspense>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </I18nextProvider>
        </ThemeProvider>
    );
};

const settings = restoreSettings();

ReactDOM.render(
    <Provider store={store}>
        <SettingsProvider settings={settings}>
            <App />
        </SettingsProvider>
    </Provider>,
    document.getElementById('root'),
);

console.log('Version : ', version);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    const siteWidth = 380;

    const initialScale = window.screen.availWidth / 980;

    (document as any)
        .querySelector('meta[name="viewport"]')
        .setAttribute(
            'content',
            `width=${siteWidth}, initial-scale=${initialScale}`,
        );
}
