import { Pagination } from 'types/pagination';

const defaultPagination: Pagination = {
    limit: 50,
    offset: 0,
    resultSet: 0,
    total: 0,
};

const defaultPaginationReducer = {
    data: defaultPagination,
    paginationLoading: false,
};

type UpdateDataByPaginationProps<T> = {
    pagination?: Pagination;
    stateData: T[];
    nextData: T[];
};

//TODO to remove after change og swagger
const parse = (p: any): Pagination =>
    !p
        ? defaultPagination
        : {
              limit: parseInt(p.limit),
              offset: parseInt(p.offset),
              resultSet: parseInt(p.resultSet),
              total: parseInt(p.total),
          };

const updateDataByPagination = <T>({
    nextData,
    pagination,
    stateData,
}: UpdateDataByPaginationProps<T>): T[] => {
    const pg = parse(pagination);

    return pg !== undefined && pg.offset > 50
        ? [...stateData, ...nextData]
        : nextData;
};

const manageRequestWithPagination = <S, P>(
    state: S,
    payload: P,
    customStateChange?: any,
) => {
    const nextState: any = { ...state };

    if ((state as any).requestData) {
        const nextPayload: any = { ...payload };
        delete nextPayload.paginationLoading;
        delete nextPayload.pagination;
        nextState.requestData = nextPayload;
    }

    if ((payload as any).paginationLoading) {
        return {
            ...nextState,
        };
    }

    return customStateChange
        ? { ...nextState, ...customStateChange }
        : { ...nextState, loading: true };
};

const addPaginationToPayload = (payload: any) => {
    if (payload.paginationLoading) {
        delete payload.paginationLoading;
    }

    return {
        ...payload,
        pagination: payload.pagination ? payload.pagination : defaultPagination,
    };
};

export {
    defaultPagination,
    updateDataByPagination,
    defaultPaginationReducer,
    manageRequestWithPagination,
    addPaginationToPayload,
    parse,
};
