import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from 'Types';

const AuthGuard = ({ children }) => {
    const { auth } = useSelector((state: RootState) => ({
        auth: state.authReducer,
    }));

    if (auth.user.userName !== '' && auth.token !== '') {
        return <Redirect to="/payment/dashboard" />;
    }

    return children;
};

export default AuthGuard;
