import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, ActionType, isActionOf } from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';

import {
    SEARCH_ENTITIES_REQUEST,
    SEARCH_ENTITIES_SUCCESS,
    SEARCH_ENTITIES_FAILURE,
} from './actionTypes';

const searchEntitiesAsync = createAsyncAction(
    SEARCH_ENTITIES_REQUEST,
    SEARCH_ENTITIES_SUCCESS,
    SEARCH_ENTITIES_FAILURE,
)<API.Entity.Search.Request, API.Entity.Search.Response, any>();

export type EntityActions = ActionType<typeof searchEntitiesAsync>;

const mapSearchEntities = (action: EntityActions, { apiRequest }: Services) => {
    return apiRequest<API.Entity.Search.Response>({
        path: '/searchEntities' as API.Entity.Search.Path,
        method: 'post',
        body: action.payload,
    }).pipe(
        mergeMap((response) => of(searchEntitiesAsync.success(response))),
        catchError((error) => of(searchEntitiesAsync.failure(error))),
    );
};

const searchEntitiesEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(searchEntitiesAsync.request)),
        mergeMap((action) => mapSearchEntities(action, dependency)),
    );

export { mapSearchEntities, searchEntitiesAsync, searchEntitiesEpic };
