import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    IconButton,
    Popover,
    Tooltip,
    Typography,
    makeStyles,
    Grid,
    Card,
} from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

import { useSelector } from 'react-redux';
import { RootState } from 'Types';

import {
    getIconFromRootName,
    getPathByModuleId,
} from '../../../utils/helpers/modules';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { isCurrentByModuleId } from 'utils/helpers/modules';

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5,
    },
    link: {
        textDecoration: 'none',
    },
    popover: {
        width: 350,
        padding: theme.spacing(2),
    },
    moduleLIst: {
        padding: theme.spacing(2),
    },
    moduleLIstItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(2),
    },
    activeItem: {
        backgroundColor: theme.palette.primary.light
            .replace(')', ', 0.20)')
            .replace('rgb', 'rgba'),
    },
    disabledItem: {
        backgroundColor: theme.palette.grey[100],
        cursor: 'initial',
    },
    defaultCard: {
        cursor: 'pointer',
        transition: 'transform .2s',
        '&:hover': {
            transform: 'scale(1.08)',
        },
    },
}));

const Modules = () => {
    const [t] = useTranslation('header/modules');
    const classes = useStyles();
    const ref = useRef(null);

    const { pathname } = useLocation();

    const { modules } = useSelector((state: RootState) => ({
        modules: state.settingReducer.modules,
    }));

    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const itemClassName = (isCurrent) =>
        isCurrent ? classes.disabledItem : classes.defaultCard;

    return (
        <>
            <Tooltip title="Modules">
                <IconButton color="inherit" onClick={handleOpen} ref={ref}>
                    <ViewModuleIcon />
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Typography variant="h4" color="textPrimary">
                    {t('title')}
                </Typography>

                <Grid container className={classes.moduleLIst} spacing={2}>
                    {modules.map((md, idx) => {
                        const IconSrc = getIconFromRootName(md.moduleId);
                        const isCurrent = isCurrentByModuleId(
                            pathname,
                            md.moduleId,
                        );

                        const Children = (
                            <Card className={itemClassName(isCurrent)}>
                                <Box className={classes.moduleLIstItem}>
                                    {IconSrc !== '' && (
                                        <img
                                            src={IconSrc}
                                            alt={md.moduleId}
                                            width="70"
                                            height="70"
                                        />
                                    )}

                                    <Typography
                                        variant="h5"
                                        color="textPrimary"
                                    >
                                        {t(`modules.${md.moduleId}`)}
                                    </Typography>
                                </Box>
                            </Card>
                        );

                        return (
                            <Grid item key={idx}>
                                {isCurrent ? (
                                    Children
                                ) : (
                                    <Link
                                        className={classes.link}
                                        to={getPathByModuleId(md.moduleId)}
                                        target="_blank"
                                    >
                                        {Children}
                                    </Link>
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
            </Popover>
        </>
    );
};

export default Modules;
