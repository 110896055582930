import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createAction,
} from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';

import {
    FETCH_CONTRACTS_REQUEST,
    FETCH_CONTRACTS_FAILURE,
    FETCH_CONTRACTS_SUCCESS,
    RESET_CONTRACTS,
} from './actionTypes';
import {
    SearchContractRequest,
    SearchContractResponse,
} from 'api-types/search-contract';
import { WithPaginationLoading } from 'types/pagination';
import { addPaginationToPayload } from 'utils/helpers/pagination';

type SearchContractPayload = WithPaginationLoading<SearchContractRequest>;

const fetchContractsAsync = createAsyncAction(
    FETCH_CONTRACTS_REQUEST,
    FETCH_CONTRACTS_SUCCESS,
    FETCH_CONTRACTS_FAILURE,
)<SearchContractPayload, SearchContractResponse, any>();

const resetContracts = createAction(RESET_CONTRACTS)();

export type ContractAction =
    | ActionType<typeof fetchContractsAsync>
    | ActionType<typeof resetContracts>;

const preparePayloadSearchCustomer = ({
    paginationLoading,
    ...payload
}: SearchContractPayload): SearchContractRequest =>
    addPaginationToPayload({
        ...payload,
    });

const mapFetchContracts = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadSearchCustomer(action.payload);
    return apiRequest<SearchContractResponse>({
        path: '/searchContract',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: SearchContractResponse) => {
            return of(fetchContractsAsync.success(response));
        }),

        catchError((error) => {
            return of(fetchContractsAsync.failure(error));
        }),
    );
};

const fetchContractsEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchContractsAsync.request)),
        mergeMap((action) => mapFetchContracts(action, dependency)),
    );

export {
    fetchContractsEpic,
    fetchContractsAsync,
    mapFetchContracts,
    resetContracts,
};
