import customerReducer from './customer.reducer';
import contractsReducer from './contract.reducer';

import paginationReducer from './pagination.reducer';

export default {
    customerCare_customer: customerReducer,
    customerCare_contract: contractsReducer,

    customerCare_pagination: paginationReducer,
};
