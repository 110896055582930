import { useTranslation } from 'react-i18next';
import { TMappingType } from 'modules/core';

import { searchLabelByData } from '../functions';

export default function useLabelByMapping() {
    const [t] = useTranslation('common');
    const allData = t('EnumMapping', {
        returnObjects: true,
    });

    const searchLabel = (value: string, type: TMappingType) => {
        return searchLabelByData(value, allData[type]);
    };

    return searchLabel;
}
