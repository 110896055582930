import { API_FetchBalanceRequest, API_FetchBalanceResponse } from 'api/payment';
import { of, throwError } from 'rxjs';
import { createAsyncAction, ActionType, isActionOf } from 'typesafe-actions';
import { RootAction, Services, RootState } from 'Types';
import { mergeMap, catchError, filter, switchMap } from 'rxjs/operators';

import {
    FETCH_BALANCE_REQUEST,
    FETCH_BALANCE_SUCCESS,
    FETCH_BALANCE_FAILURE,
} from './actionTypes';
import { Epic } from 'redux-observable';

const fetchBalanceAsync = createAsyncAction(
    FETCH_BALANCE_REQUEST,
    FETCH_BALANCE_SUCCESS,
    FETCH_BALANCE_FAILURE,
)<API_FetchBalanceRequest, API_FetchBalanceResponse, any>();

export type BalanceAction = ActionType<typeof fetchBalanceAsync>;

const mapGetBalance = (action: RootAction, { apiRequest }: Services) => {
    return apiRequest<API_FetchBalanceResponse>({
        path: '/getBalance',
        method: 'post',
        body: action.payload,
    }).pipe(
        mergeMap((response: API_FetchBalanceResponse) => {
            if (response) {
                return of(response);
            }
            return throwError(response);
        }),

        catchError((error) => {
            return of(error);
        }),
    );
};

const fetchBalanceEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchBalanceAsync.request)),

        switchMap(
            (action: RootAction) => mapGetBalance(action, dependency),
            (action: RootAction, r: any) => [action, r],
        ),
        switchMap(([action, balanceResponse]) => {
            return of(fetchBalanceAsync.success(balanceResponse));
        }),
        catchError((error) => {
            return of(fetchBalanceAsync.failure(error));
        }),
    );

export { fetchBalanceAsync, mapGetBalance, fetchBalanceEpic };
