import { combineReducers } from 'redux';

import customerCareReducers from '_reducers/modules/customer-care';
import paymentReducers from '_reducers/modules/payment';

import commonReducers from '_reducers/common';
import authReducer from '../_reducers/auth.reducer';
import settingReducer from '../_reducers/setting.reducer';

const rootReducer = combineReducers({
    authReducer,
    settingReducer,
    ...paymentReducers,
    ...customerCareReducers,
    ...commonReducers,
});

export default rootReducer;
