import letteringReducer from './lettering.reducer';
import monitoringReducer from './monitoring.reducer';
import paginationReducer from './pagination.reducer';
import revivalReducer from './revival.reducer';
import customerReducer from './search/customer.reducer';
import piecesReducer from './search/pieces.reducer';
import userReducer from './user.reducer';
import entityReducer from './entity.reducer';

export default {
    payment_pieces: piecesReducer,
    payment_customer: customerReducer,
    payment_monitoring: monitoringReducer,
    payment_revival: revivalReducer,
    payment_user: userReducer,
    payment_lettering: letteringReducer,
    payment_pagination: paginationReducer,
    payment_entity: entityReducer,
};
