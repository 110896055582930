import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, ActionType, isActionOf } from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';

import {
    SEARCH_USER_GROUP_FAILURE,
    SEARCH_USER_GROUP_SUCCESS,
    SEARCH_USER_GROUP_REQUEST,
    CREATE_USER_FAILURE,
    CREATE_USER_SUCCESS,
    CREATE_USER_REQUEST,
    MANAGE_USER_REQUEST,
    MANAGE_USER_FAILURE,
    MANAGE_USER_SUCCESS,
} from './actionTypes';

const manageUserAsync = createAsyncAction(
    MANAGE_USER_REQUEST,
    MANAGE_USER_SUCCESS,
    MANAGE_USER_FAILURE,
)<API.User.Manage.Request, ActionsTypes.User.ManageSuccess, any>();

const searchUserGroupAsync = createAsyncAction(
    SEARCH_USER_GROUP_REQUEST,
    SEARCH_USER_GROUP_SUCCESS,
    SEARCH_USER_GROUP_FAILURE,
)<API.User.Search.Request, API.User.Search.Response, any>();

const createUserAsync = createAsyncAction(
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
)<API.User.Create.Request, API.User.Create.Response, any>();

export type UserAction =
    | ActionType<typeof searchUserGroupAsync>
    | ActionType<typeof manageUserAsync>
    | ActionType<typeof createUserAsync>;

const mapSearchUserGroup = (action: UserAction, { apiRequest }: Services) => {
    const { payload } = action;
    return apiRequest<string>({
        path: '/searchUserGroup',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any) => {
            if (response.code) {
                return of(searchUserGroupAsync.failure(response));
            }
            return of(searchUserGroupAsync.success(response));
        }),

        catchError((error) => {
            return of(searchUserGroupAsync.failure(error));
        }),
    );
};

const mapManageUser = (action: UserAction, { apiRequest }: Services) => {
    const { payload } = action;
    return apiRequest<any>({
        path: '/manageUser',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any) => {
            if (response !== null) {
                if (response.code === '200') {
                    const sendToSuccess: ActionsTypes.User.ManageSuccess = {
                        ...response,
                        mode: payload.mode,
                        updatedUser: payload.userToManage,
                    };

                    return of(manageUserAsync.success(sendToSuccess));
                }

                return of(
                    manageUserAsync.failure(
                        response.message || response.description,
                    ),
                );
            }

            return of(
                manageUserAsync.failure(
                    response.message || response.description,
                ),
            );
        }),

        catchError((error) => {
            return of(manageUserAsync.failure(error));
        }),
    );
};

const searchUserGroupEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(searchUserGroupAsync.request)),
        mergeMap((action) => mapSearchUserGroup(action, dependency)),
    );

const manageUserEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(manageUserAsync.request)),
        mergeMap((action) => mapManageUser(action, dependency)),
    );

export {
    searchUserGroupAsync,
    searchUserGroupEpic,
    mapSearchUserGroup,
    createUserAsync,
    manageUserEpic,
    manageUserAsync,
};
