import React, { useState, createContext, useCallback } from 'react';

import { CircularProgress, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { APIPath, axiosRequest } from 'services/api-service';
import { manageMessage } from 'utils/helpers/api';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface APIRequest {
    path: APIPath;
    payload: any;
    snackbar?: {
        success?: string;
        failure?: string;
    };
    withoutSnackbar?: boolean;
    callbacks?: {
        success?: (props: any) => void;
        failure?: (props: any) => void;
        finally?: () => void;
    };
}

interface ISetOpenProps {
    validate?: (props?: any) => void;
    closeAfterValidate?: boolean;
    message?: string;
    props?: any;
    APIRequest?: APIRequest;
}

type TConfirmationModalContext = {
    setOpen: (props: ISetOpenProps) => void;
    open: boolean;
    setClose: () => void;
};

const ConfirmationModalContext = createContext<TConfirmationModalContext>({
    open: false,
    setOpen: () => {},
    setClose: () => {},
});

export const ConfirmationModalProvider: React.FC = ({ children }) => {
    const [t] = useTranslation('common/modal');
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpenState] = useState<boolean>(false);
    const [props, setProps] = useState<ISetOpenProps>({});

    const [loading, setLoading] = useState(false);

    const reset = () => {
        setOpenState(false);
        setProps({});
        setLoading(false);
    };

    const setOpen = (props: ISetOpenProps) => {
        setOpenState(true);
        setProps(props);
    };

    const setClose = () => {
        reset();
    };

    const handleFetch = useCallback(() => {
        const { path, payload, callbacks, snackbar, withoutSnackbar } =
            props.APIRequest as APIRequest;

        axiosRequest<any, AxiosResponse<any>>({ payload, path })
            .then((response) => {
                if (snackbar || !withoutSnackbar) {
                    enqueueSnackbar(
                        snackbar?.success ||
                            manageMessage(response) ||
                            'Success',
                        {
                            variant: 'success',
                        },
                    );
                }

                if (callbacks && callbacks.success) {
                    callbacks.success(response.data);
                }
            })
            .catch((err) => {
                if (snackbar || !withoutSnackbar) {
                    enqueueSnackbar(manageMessage(err) || 'Error', {
                        variant: 'error',
                    });
                }

                if (callbacks && callbacks.failure) {
                    callbacks.failure(err);
                }
            })
            .finally(() => {
                reset();
                if (callbacks && callbacks.finally) {
                    callbacks.finally();
                }
            });
    }, [enqueueSnackbar, props.APIRequest]);

    return (
        <ConfirmationModalContext.Provider value={{ setOpen, open, setClose }}>
            <>
                {children}
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={reset}
                    aria-labelledby="confirmation-dialog"
                    style={{
                        zIndex: 1350,
                    }}
                >
                    <DialogTitle>{t('defaultTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {(props && props.message) || t('defaultMessage')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {!loading ? (
                            <>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={reset}
                                    color="primary"
                                >
                                    {t('btn_cancel')}
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    disabled={loading}
                                    onClick={() => {
                                        if (props.validate) {
                                            props.validate(props.props);
                                        }
                                        if (props.closeAfterValidate) {
                                            reset();
                                        }

                                        if (props.APIRequest) {
                                            setLoading(true);
                                            handleFetch();
                                        }
                                    }}
                                    color="secondary"
                                >
                                    {t('btn_confirm')}
                                </Button>
                            </>
                        ) : (
                            <Box mr={2}>
                                <CircularProgress size={30} />
                            </Box>
                        )}
                    </DialogActions>
                </Dialog>
            </>
        </ConfirmationModalContext.Provider>
    );
};

export default ConfirmationModalContext;
