import React, { useState } from 'react';
import { defaultPagination } from '../constants';

import { PaginationContext } from './context';

import { RequestState } from './PaginationProvider';
import { PaginationsStateItem } from 'lib/pagination';

type RequestStates = {
    [key: string]: RequestState;
};

type Props = {
    paginationsState: PaginationsStateItem;
};

export const PaginationsProvider: React.FC<Props> = ({
    children,
    paginationsState,
}) => {
    const [requestStates, setRequestStates] = useState<RequestStates>({});

    const [currentKey, setCurrentKey] = useState<string>('');

    const request = () => {
        const requestState = requestStates[currentKey];
        if (requestState) {
            const { fn, payload } = requestState;
            const pagination = paginationsState[currentKey].data;

            fn({
                ...payload,
                pagination,
                paginationLoading: true,
            });
        }
    };

    const setRequest = (
        fn: (payload: any) => void,
        payload: any,
        key: string,
    ) => {
        setRequestStates({
            ...requestStates,
            [key as string]: { fn, payload },
        });

        setCurrentKey(key);
    };

    const paginationCheck: any = paginationsState[currentKey];

    return (
        <PaginationContext.Provider
            value={
                {
                    pagination: paginationCheck
                        ? paginationCheck.data
                        : defaultPagination,
                    request,
                    setRequest,
                    enabled: true,
                    setCurrentKey,
                    paginationLoading: paginationCheck
                        ? paginationCheck.paginationLoading
                        : false,
                } as any
            }
        >
            {children}
        </PaginationContext.Provider>
    );
};
