export enum EErrorsType {
    NON = 'NON',
    BALANCE = 'BALANCE',
    CUSTOMER = 'CUSTOMER',
    MONITORING = 'MONITORING',
    ACTIVITIES = 'ACTIVITIES',
    LOG_TRACKING = 'LOG_TRACKING',
    FETCH_GROUPS = 'FETCH_GROUPS',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    BANK_TRANSACTION = 'BANK_TRANSACTION',
    FINANCIAL_PIECES = 'FINANCIAL_PIECES',
    CANCEL_COLLECTION = 'CANCEL_COLLECTION',
    SEARCH_USER_GROUP = 'SEARCH_USER_GROUP',
    RESUBMIT_COLLECTION = 'RESUBMIT_COLLECTION',
    CREATE_LETTERING_PROPOSAL = 'CREATE_LETTERING_PROPOSAL',
    FINANCIAL_PIECES_CUSTOMER = 'FINANCIAL_PIECES_CUSTOMER',
    BANK_TRANSACTION_RESOLVED = 'BANK_TRANSACTION_RESOLVED',
    FINANCIAL_PIECES_RECONCILE = 'FINANCIAL_PIECES_RECONCILE',
    BANK_TRANSACTION_DISCARDED = 'BANK_TRANSACTION_DISCARDED',
    BANK_TRANSACTION_MANAGEMENT = 'BANK_TRANSACTION_MANAGEMENT',
    FINANCIAL_PIECES_MANAGEMENT = 'FINANCIAL_PIECES_MANAGEMENT',
    BANK_TRANSACTION_UNRESOLVED = 'BANK_TRANSACTION_UNRESOLVED',
    PAYMENT_INCIDENT_TRANSACTION = 'PAYMENT_INCIDENT_TRANSACTION',
    LETTERING_PROPOSAL_MANAGEMENT = 'LETTERING_PROPOSAL_MANAGEMENT',
}

export enum EModules {
    PAYMENT_MANAGEMENT = 'paymentManagement',
    CUSTOMER_CARE = 'customerCare',
}

export enum ELanguage {
    EN = 'EN',
    FR = 'FR',
}

export enum EThemes {
    ONE_DARK = 'ONE_DARK',
    LIGHT = 'LIGHT',
}

export enum EFinanceType {
    PREPAYMENT = 'PREPAYMENT',
    INVOICE = 'INVOICE',
    ADHOC = 'ADHOC',
}

export enum EIntermediate {
    SLIMPAY = 'slimpay',
    BANK = 'bank',
    RECOVERY_OFFICE = 'recoveryOffice',
    BALANCE = 'balance',
}

export enum EPaymentMode {
    DIRECT_DEBIT = 'directDebit',
    CHECK = 'check',
    BANK_TRANSFER = 'bankTransfer',
    BANK_CARD = 'bankCard',
    CASH = 'cash',
    ENERGY_CHECK = 'energyCheck',
}

export enum ESense {
    DEBIT = 'debit',
    CREDIT = 'credit',
    BALANCE = 'balance',
}

export enum ETransactionStatus {
    INITIALIZED = 'initialized',
    TRANSMITTED = 'transmitted',
    TRANSFER_FAILURE = 'transferFailure',
    TRANSACTION_FAILURE = 'transactionFailure',
    DETECTED_ANOMALY = 'detectedAnomaly',
    SUCCESS = 'success',
    CANCELLED = 'cancelled',
    DISPUTED = 'disputed',
}

export enum EInvoiceStatus {
    NOT_SETTLED = 'notSettled',
    SETTLED = 'settled',
    CANCELED = 'cancelled',
    AMICABLE_RECOVERY = 'amicableRecovery',
    LITIGATION_RECOVERY = 'litigationRecovery',
    LATE_PAYMENT = 'latePayment',
    OVER_PAYMENT = 'overPayment',
    EXTERNAL_RECOVERY = 'externalRecovery',
    DROPPED = 'dropped',
    SUSPENDED = 'suspended',
}

export enum EPrepaymentStatus {
    NOT_SETTLED = 'notSettled',
    SETTLED = 'settled',
    CANCELED = 'cancelled',
    INCIDENT = 'incident',
    EXTERNAL_RECOVERY = 'externalRecovery',
    DROPPED = 'dropped',
    SUSPENDED = 'suspended',
}

export enum EAdhocStatus {
    NOT_SETTLED = 'notSettled',
    SETTLED = 'settled',
    CANCELED = 'cancelled',
    INCIDENT = 'incident',
    EXTERNAL_RECOVERY = 'externalRecovery',
    DROPPED = 'dropped',
    SUSPENDED = 'suspended',
}

export enum EContractStatus {
    RECOVERY = 'Recovery',
    WAITING = 'waiting',
    CANCELLED = 'Cancelled',
    ABANDONED = 'abandoned',
    REJECTed = 'Rejected',
    ACCEPTED = 'Accepted',
    EFFECTIVE = 'effective',
    TERMINATED = 'terminated',
    SEND_TO_MKT = 'sendToMkt',
    INITIALIZED = 'initialized',
    WAITING_14D = 'waiting-14d',
    VALIDATION_REJECT = 'ValidationReject',
}

export enum EClientType {
    RESIDENTIAL = 'residential',
    PROFESSIONAL = 'professional',
}

export enum EProcessStatus {
    DROPPED = 'dropped',
    TERMINATED = 'terminated',
    IN_PROGRESS = 'inProgress',
}

export enum EPiecesType {
    INVOICE = 'in',
    PREPAYMENT = 'pp',
    ADHOC = 'adhocPieces',
}

export enum EProposalStatus {
    CANCELED = 'canceled',
    VALIDATED = 'validated',
    TO_VALIDATE = 'toValidate',
    ERROR_DETECTED = 'errorDetected',
}

export enum EProposalType {
    MANUAL = 'manual',
    AUTO_IA = 'autoIA',
    AUTO_ALGO = 'autoAlgo',
}

export enum ECounterpartType {
    IN = 'in',
    PP = 'pp',
    BALANCE = 'balance',
    DEPOSIT = 'deposit',
    OVER_CHARGE = 'overCharge',
}

export enum EValidationMode {
    AUTO = 'auto',
    MANUAL = 'manual',
}

export enum EBankTransactionStatus {
    WAITING = 'waiting',
    LETTERED = 'lettered',
    DISCARDED = 'discarded',
    IN_PROPOSAL = 'inProposal',
    TO_BE_LETTERED = 'toBeLetter',
    TO_BE_QUALIFIED = 'toBeQualified',
}

export enum EModeFinancialPieces {
    DROP = 'drop',
    CANCEL = 'cancel',
    RECOVERY = 'recoveryOfficeTransfer',
    SUSPEND = 'suspend',
    NOT_SETTLED = 'notSettled',
}

export enum EModeLetteringProposalManagement {
    CANCELLATION = 'cancellation',
    VALIDATION_AUTO = 'validationAuto',
    VALIDATION_MANU = 'validationManu',
}

export enum EProcessNature {
    INVOICE = 'in',
    PREPAYMENT = 'pp',
    BALANCE = 'balance',
    RECOVERY = 'recovery',
    ENERGY_CHEQUE = 'energyCheck',
    ACTIVATION = 'contractActivation',
    SECURITY_DEPOSIT = 'securityDeposit',
    EXTERNAL_RECOVERY_INVOICE = 'externalRecoveryOnIn',
    EXTERNAL_RECOVERY_PREPAYMENT = 'externalRecoveryOnPP',
}

export enum EActivityStatus {
    PENDING = 'pending',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
    SUSPENDED = 'suspended',
    IN_PROGRESS = 'inProgress',
}

export enum EActivityCategory {
    COLLECTION = 'COLLECTION',
}

export enum EActivitySubCategory {
    AMICABLE = 'AMICABLE',
    LITIGATION = 'LITIGATION',
}

export enum EEventStatus {
    PENDING = 'pending',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
    SUSPENDED = 'suspended',
    IN_FAILURE = 'inFailure',
}

export enum EExecutionMode {
    AUTO = 'AUTO',
    MANUAL = 'MANUAL',
}

export enum EDocumentObjectType {
    LOG = 'LOG',
    EVENT = 'EVENT',
    CUSTOMER = 'CUSTOMER',
    CONTRACT = 'CONTRACT',
    ACTIVITY = 'ACTIVITY',
    ALLOCATION = 'ALLOCATION',
}

export enum EDocumentType {
    OTHER = 'OTHER',
    RIDER = 'RIDER',
    CONTRACT = 'CONTRACT',
    SEPA_MANDATE = 'SEPA_MANDATE',
    PAYMENT_PLAN = 'PAYMENT_PLAN',
    FORCEFUL_LETTER = 'FORCEFUL_LETTER',
    AMICABLE_LETTER_1 = 'AMICABLE_LETTER_1',
    AMICABLE_LETTER_2 = 'AMICABLE_LETTER_2',
}

export enum EMonitoringObjet {
    PROCESS = 'PROCESS',
    INVOICE = 'INVOICE',
    CONTRACT = 'CONTRACT',
    CUSTOMER = 'CUSTOMER',
    PREPAYMENT = 'PREPAYMENT',
    ALLOCATION = 'ALLOCATION',
    TRANSACTION = 'TRANSACTION',
    Third_Payeur = 'Third Payeur',
    Actor_Payeur = 'Actor Payeur',
    PAYMENT_ISSUES = 'PAYMENT-ISSUES',
    Actor_Destinataire = 'Actor Destinataire',
    Third_Destinataire = 'Third Destinataire',
    BankFinancialTransaction = 'BankFinancialTransaction',
    BankFinancialInformationTracking = 'BankFinancialInformationTracking',
}

export enum ELogStatus {
    IN_PROGRESS = 'inProgress',
    TERMINATED = 'terminated',
    CANCELLED = 'cancelled',
    SUSPENDED = 'suspended',
    IN_FAILURE = 'inFaillure',
}

export enum ELogAction {
    UPDATE = 'update',
    STATUS_CHANGE = 'statusChange',
}

export enum EAllocationStatus {
    TO_ALLOCATE = 'toAllocate',
    ALLOCATED = 'allocated',
    CANCELED = 'cancelled',
    TO_CANCELLED = 'toCancel',
}

export enum EManageAllocationsMode {
    CANCEL = 'cancel',
    ALLOCATE = 'allocate',
    CREATE_ADJUSTMENT = 'createAdjustment',
}

export enum EUserStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export enum EAuthorizationMode {
    FULL = 'FULL',
    LIMITED = 'LIMITED',
}

export enum EAuthorizationActionMode {
    READ_ONLY = 'READ_ONLY',
    FULL = 'FULL',
}

export enum EMonitoringActions {
    UPDATE_PROCESS_TRANSACTION = 'UPDATE_PROCESS_TRANSACTION',
    INSERT_PROCESS_TRANSACTION = 'INSERT_PROCESS_TRANSACTION',
    LAUNCH_PAYIN_PAYOUT = 'LAUNCH_PAYIN_PAYOUT',
    IMPORT_IN_PP = 'IMPORT_IN_PP',
    IMPORT_PAYMENT_REJECTION = 'IMPORT_PAYMENT_REJECTION',
}

export enum EManageUserMode {
    ENABLE = 'enable',
    DISABLE = 'disable',
    DELETE = 'delete',
    UPDATE = 'update',
}

export enum ECustomerCategory {
    CUSTOMER_B2C = 'customerB2C',
    CUSTOMER_B2B = 'customerB2B',
}

export enum EPerimeterType {
    MONO_SITE = 'MONO_SITE',
    MULTI_SITE = 'MULTI_SITE',
}

export enum EDirection {
    RANKING = 'RANKING',
    INJECTION = 'INJECTION',
}

export enum EBillingMode {
    CYCLICAL_BILLING = 'CYCLICAL_BILLING',
    PAYMENT_SCHEDULE = 'PAYMENT_SCHEDULE',
}

export enum EEnergyType {
    EL = 'EL',
    NG = 'NG',
}

export enum EPointOfServiceState {
    IN_USE = 'IN_USE',
    NOT_IN_USE = 'NOT_IN_USE',
    INSIDE_CUT = 'INSIDE_CUT',
    CUT = 'CUT',
    UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION',
    UNKNOWN = 'UNKNOWN',
}
