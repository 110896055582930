const redirectByTypePiece = (payload) => {
    let link = '';

    Object.keys(payload).forEach((key) => {
        if (key !== 'updatedSections') {
            link = `?type=${key}`;
        }
    });

    if ((window as any).history.pushState) {
        const newUrl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            link;
        window.history.pushState({ path: newUrl }, '', newUrl);
    }
};

export { redirectByTypePiece };
