import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as balanceActions from '_actions/modules/payment/balance.actions';
import * as actions from '_actions/modules/payment/customer.actions';
import { API_FetchBalanceResponse } from 'api/payment';
import { Customer } from 'types/customer';
import { FinancialPieces } from 'types/financial-pieces';
import {
    manageRequestWithPagination,
    updateDataByPagination,
} from 'utils/helpers/pagination';
import {
    replaceOnePieceOnPiecesReducer,
    updateOneThirdOnCustomerReducer,
} from 'utils/modules/payment-functions';

export type CustomersState = Readonly<{
    customers: Customer[];
    financialPieces: FinancialPieces;
    balance?: API_FetchBalanceResponse;

    loadingFetchCustomers: boolean;
    error?: any;
}>;

const initialState = {
    customers: [],
    financialPieces: {
        invoices: undefined,
        prepayments: undefined,
        adhocPieces: undefined,
    },

    balance: undefined,

    loadingFetchCustomers: false,
    error: undefined,
};

type Actions = actions.CustomersAction | balanceActions.BalanceAction;

const customersReducer: Reducer<CustomersState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchCustomerContractAsync.request): {
            return manageRequestWithPagination(state, action.payload, {
                ...initialState,
                loadingFetchCustomers: true,
            });
        }
        case getType(actions.fetchCustomerContractAsync.success): {
            const customers = updateDataByPagination<Customer>({
                pagination: (action.payload as any).pagination,
                nextData: action.payload.customers,
                stateData: state.customers,
            });
            return {
                ...state,
                customers: customers,
                loadingFetchCustomers: false,
            };
        }

        case getType(actions.fetchCustomerContractAsync.failure):
            return {
                ...state,
                customers: [],
                loadingFetchCustomers: false,
                error: action.payload,
            };

        case getType(actions.resetCustomers): {
            return {
                ...state,
                balance: undefined,
                customers: [],
                financialPieces: {},
                error: undefined,
            };
        }

        case getType(actions.fetchCustomerFPAsync.success): {
            return {
                ...state,
                financialPieces: action.payload,
            };
        }

        case getType(actions.fetchCustomerFPAsync.failure): {
            return {
                ...state,
                financialPieces: {},
            };
        }

        case getType(balanceActions.fetchBalanceAsync.success):
            return {
                ...state,
                balance: action.payload,
            };

        case getType(balanceActions.fetchBalanceAsync.failure):
            return {
                ...state,
                balance: undefined,
            };

        case getType(actions.fetchCustomerOneFPAsync.success):
            return {
                ...state,
                financialPieces: replaceOnePieceOnPiecesReducer(
                    action.payload,
                    state.financialPieces,
                ),
            };

        case getType(actions.updateCustomerThird):
            return updateOneThirdOnCustomerReducer(action.payload, state);
        default:
            return { ...state };
    }
};

export default customersReducer;
