import { Pagination } from 'types/pagination';

import { defaultPagination } from '../constants';

// TODO: unuseful if pagination object props is number <int>
export default (p: any): Pagination =>
    !p
        ? defaultPagination
        : {
              limit: parseInt(p.limit),
              offset: parseInt(p.offset),
              resultSet: parseInt(p.resultSet),
              total: parseInt(p.total),
          };
