import axios from 'axios';

import config from './config';

const instance = axios.create({
  baseURL: config.URL,
  headers: {
    post: (() => {
      const token = localStorage.getItem('token');

      return {
        Authorization: `Bearer ${token}`,
      };
    })(),
  },
});

export default instance;
