import { isString } from 'lodash';
import { Action as ReduxAction, Reducer } from 'redux';

import getFunctionByActionType from './functions/getFunctionByActionType';

import {
    PaginationState,
    CallbacksProps,
    ActionPagination,
} from 'lib/pagination';

const initialState: PaginationState = {
    keys: [],
    paginations: {},
};

export default <A>(
    callbacks: CallbacksProps<A>,
): Reducer<PaginationState, ReduxAction> => {
    return (state = initialState, action) => {
        const { type } = action as any;
        const fn = callbacks[type];

        if (isString(fn)) {
            return getFunctionByActionType(type)(
                state,
                (action as ActionPagination).payload,
                fn,
            );
        }

        if (fn) return fn(state, action as any, getFunctionByActionType(type));
        return state;
    };
};
