import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { Contract } from 'types/contract';
import * as actions from '_actions/modules/customer-care/contract.actions';
import {
    manageRequestWithPagination,
    updateDataByPagination,
} from 'utils/helpers/pagination';

export type ContractsState = Readonly<{
    contracts: Contract[];
    loadingSearchContract: boolean;
    error?: any;
    requestData: any;
}>;

const initialState: ContractsState = {
    contracts: [],
    loadingSearchContract: false,
    requestData: {},
};

type Actions = actions.ContractAction;

const contractsReducer: Reducer<ContractsState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchContractsAsync.request): {
            return manageRequestWithPagination(state, action.payload, {
                ...initialState,
                loadingSearchContract: true,
            });
        }

        case getType(actions.fetchContractsAsync.success): {
            const contracts = updateDataByPagination<Contract>({
                pagination: action.payload.pagination,
                nextData: action.payload.contracts,
                stateData: state.contracts,
            });

            return {
                ...initialState,
                contracts,
                requestData: state.requestData,
            };
        }

        case getType(actions.fetchContractsAsync.failure):
            return {
                ...initialState,
                error: action.payload,
            };

        case getType(actions.resetContracts): {
            return { ...initialState };
        }

        default:
            return { ...state };
    }
};

export default contractsReducer;
