export const FETCH_CUSTOMERS_REQUEST = '@customer-care/fetch-customers-request';
export const FETCH_CUSTOMERS_FAILURE = '@customer-care/fetch-customers-failure';
export const FETCH_CUSTOMERS_SUCCESS = '@customer-care/fetch-customers-success';
export const RESET_CUSTOMERS = '@customer-care/reset-customers';

export const FETCH_ACTIVITIES_REQUEST =
    '@customer-care/fetch-activities-request';
export const FETCH_ACTIVITIES_FAILURE =
    '@customer-care/fetch-activities-failure';
export const FETCH_ACTIVITIES_SUCCESS =
    '@customer-care/fetch-activities-success';

export const FETCH_CONTRACTS_REQUEST = '@customer-care/fetch-contracts-request';
export const FETCH_CONTRACTS_FAILURE = '@customer-care/fetch-contracts-failure';
export const FETCH_CONTRACTS_SUCCESS = '@customer-care/fetch-contracts-success';
export const RESET_CONTRACTS = '@customer-care/reset-contracts';
