import { EModeLetteringProposalManagement } from 'constants/enums';

import { CreateLetteringProposalRequest } from 'api-types/create-lettering-proposal';
import {
    GetBankTransactionRequest,
    GetBankTransactionResponse,
} from 'api-types/get-bank-transaction';
import { TPieces } from 'Models';
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createAction,
} from 'typesafe-actions';

import { API_FetchFinancialPiecesRequest } from 'api/payment';
import {
    RootAction,
    RootState,
    Services,
    ReducerError,
    LetteringSection,
    TModalErrorType,
} from 'Types';
import { BankTransaction } from 'types/bank-transaction';
import { BankTransactionStatus } from 'types/bank-transaction';
import { Pagination } from 'types/pagination';

import {
    FETCH_BANK_TRANSACTION_REQUEST,
    FETCH_BANK_TRANSACTION_SUCCESS,
    FETCH_BANK_TRANSACTION_FAILURE,
    FETCH_RECONCILE_FINANCIAL_PIECES_FAILURE,
    FETCH_RECONCILE_FINANCIAL_PIECES_REQUEST,
    FETCH_RECONCILE_FINANCIAL_PIECES_SUCCESS,
    LETTERING_PROPOSAL_MANAGEMENT_REQUEST,
    LETTERING_PROPOSAL_MANAGEMENT_FAILURE,
    LETTERING_PROPOSAL_MANAGEMENT_SUCCESS,
    BANK_TRANSACTION_MANAGEMENT_REQUEST,
    BANK_TRANSACTION_MANAGEMENT_SUCCESS,
    BANK_TRANSACTION_MANAGEMENT_FAILURE,
    CREATE_LETTERING_PROPOSAL_REQUEST,
    CREATE_LETTERING_PROPOSAL_FAILURE,
    CREATE_LETTERING_PROPOSAL_SUCCESS,
    RESET_LETTERING_ERROR,
} from './actionTypes';

export type FetchBankTransactionRequest = GetBankTransactionRequest & {
    currentSection: LetteringSection;
};

export type TLetteringProposalManagementPayload = {
    lunchMode: EModeLetteringProposalManagement;
    selectedItems?: BankTransaction[];
};

export type TLetteringProposalManagementRequest = {
    lunchMode: EModeLetteringProposalManagement;
    proposalIdList?: string[];
};

export type TBankTransactionReducer = {
    transactions: BankTransaction[];
    pagination?: Pagination;
    currentSection: LetteringSection;
};

export type TBankTransactionManagementRequest = {
    bankTransactionIdList: string[];
    bankTransactionStatus: BankTransactionStatus;
    comment?: string;
};

const fetchBankTransactionAsync = createAsyncAction(
    FETCH_BANK_TRANSACTION_REQUEST,
    FETCH_BANK_TRANSACTION_SUCCESS,
    FETCH_BANK_TRANSACTION_FAILURE,
)<FetchBankTransactionRequest, TBankTransactionReducer, any>();

const createLetteringProposalAsync = createAsyncAction(
    CREATE_LETTERING_PROPOSAL_REQUEST,
    CREATE_LETTERING_PROPOSAL_SUCCESS,
    CREATE_LETTERING_PROPOSAL_FAILURE,
)<CreateLetteringProposalRequest, any, TModalErrorType>();

const fetchReconcileFPAsync = createAsyncAction(
    FETCH_RECONCILE_FINANCIAL_PIECES_REQUEST,
    FETCH_RECONCILE_FINANCIAL_PIECES_SUCCESS,
    FETCH_RECONCILE_FINANCIAL_PIECES_FAILURE,
)<API_FetchFinancialPiecesRequest, TPieces[], ReducerError>();

const letteringProposalManagementAsync = createAsyncAction(
    LETTERING_PROPOSAL_MANAGEMENT_REQUEST,
    LETTERING_PROPOSAL_MANAGEMENT_SUCCESS,
    LETTERING_PROPOSAL_MANAGEMENT_FAILURE,
)<TLetteringProposalManagementRequest, any, any>();

const bankTransactionManagementAsync = createAsyncAction(
    BANK_TRANSACTION_MANAGEMENT_REQUEST,
    BANK_TRANSACTION_MANAGEMENT_SUCCESS,
    BANK_TRANSACTION_MANAGEMENT_FAILURE,
)<TBankTransactionManagementRequest, any, ReducerError>();

const resetLetteringError = createAction(RESET_LETTERING_ERROR)();

export type LetteringAction =
    | ActionType<typeof fetchBankTransactionAsync>
    | ActionType<typeof createLetteringProposalAsync>
    | ActionType<typeof fetchReconcileFPAsync>
    | ActionType<typeof letteringProposalManagementAsync>
    | ActionType<typeof bankTransactionManagementAsync>
    | ActionType<typeof resetLetteringError>;

const prepareBankTransactionPayload = ({
    bankAccountId,
    bankTransactionStatusList,
    fromDate,
    toDate,
}: FetchBankTransactionRequest): GetBankTransactionRequest => {
    return {
        bankTransactionId: bankAccountId,
        bankTransactionStatusList,
        fromDate,
        toDate,
    };
};

const mapGetBankTransaction = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = prepareBankTransactionPayload(action.payload);
    return apiRequest<GetBankTransactionResponse>({
        path: '/getBankTransaction',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response) => {
            const prepareResponse: TBankTransactionReducer = {
                currentSection: action.payload.currentSection,
                transactions: response.transaction,
                pagination: response.pagination,
            };
            return of(fetchBankTransactionAsync.success(prepareResponse));
        }),

        catchError((error) => {
            return of(fetchBankTransactionAsync.failure(error));
        }),
    );
};

const bankTransactionEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchBankTransactionAsync.request)),
        mergeMap((action) => mapGetBankTransaction(action, dependency)),
    );

const mapCreateLetteringProposal = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    return apiRequest<any>({
        path: '/createLetteringProposal',
        method: 'post',
        body: action.payload,
        requestIsArray: true,
    }).pipe(
        mergeMap((response: any) => {
            return of(createLetteringProposalAsync.success(response));
        }),

        catchError((error) => of(createLetteringProposalAsync.failure(error))),
    );
};

const createLetteringProposalEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(createLetteringProposalAsync.request)),
        mergeMap((action) => mapCreateLetteringProposal(action, dependency)),
    );

const prepareLPManagement = (payload: TLetteringProposalManagementRequest) => ({
    ...payload,
});

const mapLetteringProposalManagement = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = prepareLPManagement(action.payload);
    return apiRequest<any>({
        path: '/letteringProposalManagement',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any) => {
            return of(letteringProposalManagementAsync.success(response));
        }),

        catchError((error) =>
            of(letteringProposalManagementAsync.failure(error)),
        ),
    );
};

const prepareBankTransactionManagement = (
    payload: TBankTransactionManagementRequest,
) => ({ ...payload });

const mapBankTransactionManagement = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = prepareBankTransactionManagement(action.payload);
    return apiRequest<any>({
        path: '/bankTransactionManagement',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any) => {
            return of(bankTransactionManagementAsync.success(response));
        }),

        catchError((error) => {
            return of(bankTransactionManagementAsync.failure(error));
        }),
    );
};

const bankTransactionManagementEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(bankTransactionManagementAsync.request)),
        mergeMap((action) => mapBankTransactionManagement(action, dependency)),
    );

const letteringProposalManagementEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(letteringProposalManagementAsync.request)),
        mergeMap((action) =>
            mapLetteringProposalManagement(action, dependency),
        ),
    );

/* const fetchReconcileFinancialPiecesEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(fetchReconcileFPAsync.request)),
        switchMap(
            (action: RootAction) => mapGetFinancialPieces(action, dependency),
            (action: RootAction, r: any) => [action, r],
        ),

        switchMap(([action, financialPiecesResponse]) => {
            if (!!get(financialPiecesResponse, 'error')) {
                return of(
                    fetchReconcileFPAsync.failure(financialPiecesResponse),
                );
            }

            const res = controlPiecesReconcile(financialPiecesResponse);

            if (!isEmpty(res)) {
                return of(fetchReconcileFPAsync.success(res));
            }

            return of(
                fetchReconcileFPAsync.failure({
                    errType: EErrorsType.FINANCIAL_PIECES_RECONCILE,
                    error: {
                        code: '400',
                        description: 'ajax error 400',
                        errors: [
                            {
                                code: 'NO_RESULT',
                                description: 'Aucun résultat.',
                            },
                        ],
                    },
                }),
            );
        }),

        catchError((error) => {
            return of(fetchBankTransactionAsync.failure(error));
        }),
    ); */

export {
    fetchBankTransactionAsync,
    createLetteringProposalAsync,
    mapCreateLetteringProposal,
    mapGetBankTransaction,
    createLetteringProposalEpic,
    bankTransactionEpic,
    resetLetteringError,
    bankTransactionManagementAsync,
    letteringProposalManagementAsync,
    fetchReconcileFPAsync,
    mapBankTransactionManagement,
    bankTransactionManagementEpic,
    /* fetchReconcileFinancialPiecesEpic, */
    mapLetteringProposalManagement,
    letteringProposalManagementEpic,
};
