import buildPaginationReducer from './buildReducer';

import getType from './functions/getType';
import buildActionsByKey from './functions/buildActionsByKey';

import usePagination from './hooks/usePagination';
import usePaginationRequest from './hooks/usePaginationRequest';

import { PaginationProvider } from './context/PaginationProvider';
import { PaginationsProvider } from './context/PaginationsProvider';

export {
    buildPaginationReducer,
    getType,
    usePagination,
    PaginationProvider,
    PaginationsProvider,
    usePaginationRequest,
    buildActionsByKey,
};
