import { combineEpics } from 'redux-observable';

import { authEpics } from '_actions/auth.actions';
import customerCareEpics from '_actions/modules/customer-care/epics';
import paymentEpics from '_actions/modules/payment/epics';

export default combineEpics(
    ...authEpics,
    ...customerCareEpics,
    ...paymentEpics,
);
