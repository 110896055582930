import React from 'react';

import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    SvgIcon,
} from '@material-ui/core';
import clsx from 'clsx';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

import { Logo } from '../../../components';
import { EThemes } from '../../../constants/enums';
import Account from './Account';
import Modules from './Modules';
import Notifications from './Notifications';
import Settings from './Settings';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            zIndex: theme.zIndex.drawer,
            ...((theme as any).name === EThemes.LIGHT
                ? {
                      boxShadow: 'none',
                      backgroundColor: theme.palette.primary.main,
                  }
                : {}),
            ...((theme as any).name === EThemes.ONE_DARK
                ? {
                      backgroundColor: theme.palette.background.default,
                  }
                : {}),
        },
        toolbar: {
            minHeight: 64,
        },

        menuButton: {},
    };
});

type TTopBarProps = {
    onMobileNavOpen: () => void;
    toggleDrawer: () => void;
    className?: string;
};

const TopBar: React.FC<TTopBarProps> = ({
    className,
    onMobileNavOpen,
    toggleDrawer,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        onClick={onMobileNavOpen}
                    >
                        <SvgIcon fontSize="small">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <IconButton color="inherit" onClick={toggleDrawer}>
                            <SvgIcon fontSize="small">
                                <MenuIcon />
                            </SvgIcon>
                        </IconButton>
                        <RouterLink to="/">
                            <Logo />
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box ml={2} flexGrow={1} />

                <Notifications />
                <Modules />
                <Settings />

                <Box ml={2}>
                    <Account />
                </Box>

                {/* <Search />
                <Contacts />
                */}
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
