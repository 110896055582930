import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { Customer } from 'types/customer';
import * as actions from '_actions/modules/customer-care/customer.actions';
import {
    updateDataByPagination,
    manageRequestWithPagination,
} from 'utils/helpers/pagination';

export type CustomersState = Readonly<{
    customers: Customer[];
    loading: boolean;
    error?: any;

    requestData: any;
}>;

const initialState: CustomersState = {
    customers: [],
    loading: false,
    error: undefined,
    requestData: {},
};

type Actions = actions.CustomerAction;

const customersReducer: Reducer<CustomersState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchCustomersAsync.request): {
            return manageRequestWithPagination(state, action.payload);
        }
        case getType(actions.fetchCustomersAsync.success): {
            const customers = updateDataByPagination<Customer>({
                pagination: action.payload.pagination,
                nextData: action.payload.customers,
                stateData: state.customers,
            });

            return {
                ...state,
                customers,
                loading: false,
                error: undefined,
            };
        }

        case getType(actions.fetchCustomersAsync.failure):
            return {
                ...initialState,
                error: action.payload,
            };

        case getType(actions.resetCustomers): {
            return { ...initialState };
        }

        default:
            return { ...state };
    }
};

export default customersReducer;
