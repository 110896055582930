/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import usePagination from './usePagination';

type Props = {
    requestFn: any;
    payload: any;
    key?: string;
};

const usePaginationRequest = <T = any>({
    payload,
    requestFn,
    key,
}: Props): T => {
    const hook = usePagination();

    useEffect(() => {
        hook.setRequest(requestFn, payload, key);
    }, []);

    useEffect(() => {
        hook.setRequest(requestFn, payload, key);
    }, [payload]);

    return hook;
};

export default usePaginationRequest;
