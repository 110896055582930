import { createAction, ActionType } from 'typesafe-actions';
import { REPLACE_RESUBMIT_TRANSACTION } from './actionTypes';
import { Action_ReplaceResubmitTransaction } from 'api/payment';

const replaceResubmitTransaction = createAction(REPLACE_RESUBMIT_TRANSACTION)<
  Action_ReplaceResubmitTransaction
>();

export type TransactionAction = ActionType<typeof replaceResubmitTransaction>;

export { replaceResubmitTransaction };
