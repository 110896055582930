import { AuthState } from '../_reducers/auth.reducer';
import { TLoginResponse } from '../_actions/auth.actions';

import jwtDecode from 'jwt-decode';

class AuthService {
    public static authenticate({ accessToken, token, user }: TLoginResponse) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        localStorage.setItem('accessToken', accessToken);

        const decoded = jwtDecode(token);

        localStorage.setItem('expire', (decoded as any).exp);
    }

    public static checkTokenExpire(): boolean {
        const expire = localStorage.getItem('expire');
        const currentTime = Date.now() / 1000;
        return Number(expire) > currentTime;
    }

    public static logout(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        localStorage.removeItem('reduxState');
        localStorage.removeItem('expire');
    }

    public static isAuthenticated(): boolean {
        const token = localStorage.getItem('token');
        const accessToken = localStorage.getItem('accessToken');
        const user = localStorage.getItem('user');
        return (
            token !== undefined &&
            token !== null &&
            accessToken !== undefined &&
            accessToken !== null &&
            user !== undefined &&
            user !== null
        );
    }

    public static checkAuthState = ({
        authorizationsSchema,
        error,
        user: { userName, group },
        token,
    }: AuthState) =>
        error === undefined &&
        authorizationsSchema &&
        authorizationsSchema.length !== 0 &&
        group !== '' &&
        token !== '' &&
        userName !== '';
}

export default AuthService;
