import manageRequest from './manageRequest';
import manageSuccess from './manageSuccess';
import manageReset from './manageReset';

export default (type: string) => {
    if (type.toLocaleLowerCase().includes('request')) return manageRequest;
    if (type.toLocaleLowerCase().includes('success')) return manageSuccess;
    if (type.toLocaleLowerCase().includes('reset')) return manageReset;

    return manageRequest;
};
