import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    Avatar,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    SvgIcon,
    Tooltip,
    Typography,
    makeStyles,
} from '@material-ui/core';
import {
    Bell as BellIcon,
    Package as PackageIcon,
    MessageCircle as MessageIcon,
    Truck as TruckIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';

const iconsMap = {
    order_placed: PackageIcon,
    new_message: MessageIcon,
    item_shipped: TruckIcon,
};

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320,
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    listItem: {},
    list: {},
}));

const notifications = [
    {
        type: 'new_message',
        id: 854651652,
        title: 'Notification ',
        description: 'new notif',
    },

    {
        type: 'new_message',
        id: 786767876753,
        title: 'New Module!! => Payment ',
        description: 'new module',
    },
];

const Notifications = () => {
    const [t] = useTranslation('header/notifications');
    const classes = useStyles();
    const ref = useRef(null);
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {}, [dispatch]);

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton color="inherit" ref={ref} onClick={handleOpen}>
                    <SvgIcon>
                        <BellIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Box p={2}>
                    <Typography variant="h5" color="textPrimary">
                        {t('title')}
                    </Typography>
                </Box>
                {notifications.length === 0 ? (
                    <Box p={2}>
                        <Typography variant="h6" color="textPrimary">
                            {t('noNotification')}
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <List className={classes.list} disablePadding>
                            {notifications.map((notification) => {
                                const Icon = iconsMap[notification.type];

                                return (
                                    <ListItem
                                        className={classes.listItem}
                                        component={RouterLink}
                                        divider
                                        key={notification.id}
                                        to="#"
                                    >
                                        <ListItemAvatar>
                                            <Avatar className={classes.icon}>
                                                <SvgIcon fontSize="small">
                                                    <Icon />
                                                </SvgIcon>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={notification.title}
                                            primaryTypographyProps={{
                                                variant: 'subtitle2',
                                                color: 'textPrimary',
                                            }}
                                            secondary={notification.description}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                        <Box p={1} display="flex" justifyContent="center">
                            <Button component={RouterLink} size="small" to="#">
                                {t('markedAsRead')}
                            </Button>
                        </Box>
                    </>
                )}
            </Popover>
        </>
    );
};

export default Notifications;
