import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    createAction,
    ActionType,
} from 'typesafe-actions';
import { RootAction, RootState, Services, TError } from 'Types';

import {
    FETCH_LOGIN_REQUEST,
    FETCH_LOGIN_SUCCESS,
    FETCH_LOGIN_FAILURE,
    REDUX_RESET_STATE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
} from './actionTypes';
import { TAuthorizationSchema, TUser } from 'Models';
import AuthService from '../services/auth-service';

export type TLoginRequest = {
    user: string;
    password: string;
};

export type TResetPasswordRequest = {
    customerNbr: string;
};

export type TLoginResponse = {
    user: TUser;
    token: string;
    accessToken: string;
    authorizationsScheme: TAuthorizationSchema[];
};

const fetchLoginAsync = createAsyncAction(
    FETCH_LOGIN_REQUEST,
    FETCH_LOGIN_SUCCESS,
    FETCH_LOGIN_FAILURE,
)<TLoginRequest, TLoginResponse, any>();

const resetReduxStore = createAction(REDUX_RESET_STATE)();

const resetPasswordAsync = createAsyncAction(
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
)<TResetPasswordRequest, TResetPasswordRequest, any>();

export type TChangePasswordRequest = {
    oldPassword: string;
    newPassword: string;
    accessToken: string;
};

const changePasswordAsync = createAsyncAction(
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
)<TChangePasswordRequest, any, TError | TError[]>();

export type AuthAction =
    | ActionType<typeof fetchLoginAsync>
    | ActionType<typeof resetPasswordAsync>
    | ActionType<typeof changePasswordAsync>
    | ActionType<typeof resetReduxStore>;

const preparePayloadLogin = ({ user, password }: TLoginRequest) => {
    return {
        user,
        password,
    };
};

const preparePayloadResetPassword = ({
    customerNbr,
}: TResetPasswordRequest) => {
    return {
        customerNbr,
    };
};

const mapPostLogin = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadLogin(action.payload);
    return apiRequest<TLoginResponse>({
        path: '/login',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: TLoginResponse | TError) => {
            const mocksLoging = {
                accessToken: (response as any).accessToken,
                token: (response as any).token,
                user: (response as any).user,
                authorizationsScheme: (response as any)
                    .authorizationsScheme as any,
            };
            AuthService.authenticate(mocksLoging as TLoginResponse);

            return of(fetchLoginAsync.success(mocksLoging as TLoginResponse));
        }),
        catchError((error) => {
            return of(fetchLoginAsync.failure(error));
        }),
    );
};

const mapResetPassword = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadResetPassword(action.payload);
    return apiRequest<any>({
        path: '/resetPassword',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any) => {
            if (response && (response as TError).code === undefined) {
                return of(resetPasswordAsync.success(response));
            }

            return of(resetPasswordAsync.failure(response));
        }),
        catchError((error) => {
            return of(resetPasswordAsync.failure(error));
        }),
    );
};

const preparePayloadChangePassword = (payload: TChangePasswordRequest) => ({
    ...payload,
});

const mapChangePassword = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadChangePassword(action.payload);
    return apiRequest<any>({
        path: '/changePassword',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any) => {
            if (response && (response as TError).code === undefined) {
                return of(changePasswordAsync.success(response));
            }

            return of(changePasswordAsync.failure(response));
        }),
        catchError((error) => {
            return of(changePasswordAsync.failure(error));
        }),
    );
};

const fetchLoginEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchLoginAsync.request)),
        mergeMap((action: RootAction) => mapPostLogin(action, dependency)),
    );

const resetPasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(resetPasswordAsync.request)),
        mergeMap((action: RootAction) => mapResetPassword(action, dependency)),
    );

const changePasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(changePasswordAsync.request)),
        mergeMap((action: RootAction) => mapChangePassword(action, dependency)),
    );

const authEpics = [changePasswordEpic, fetchLoginEpic, resetPasswordEpic];

export {
    fetchLoginEpic,
    fetchLoginAsync,
    changePasswordEpic,
    changePasswordAsync,
    resetReduxStore,
    resetPasswordAsync,
    resetPasswordEpic,
    authEpics,
};
