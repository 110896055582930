import Lambda, { InvocationResponse } from 'aws-sdk/clients/lambda';
import { AWSError } from 'aws-sdk/lib/error';
import isArray from 'lodash/isArray';
import { throwError, Observable, from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';

import axios from 'utils/axios';

import Config from '../utils/config';
import { consoleLog, apiLog, addUserToBody } from '../utils/helpers';

export const invokeLambda = <T>(functionName: string, payload: object) => {
    const lambdaInstance = new Lambda(Config.aws);

    apiLog('REQUEST', functionName, payload, true);

    return new Promise((resolve, reject) => {
        if (!functionName || functionName === '') {
            reject('Lambda function name > unavailable!');
        }
        lambdaInstance.invoke(
            {
                FunctionName: functionName,
                Payload: JSON.stringify(payload),
                InvocationType: 'RequestResponse',
                LogType: 'None',
            },
            (err: AWSError, res: InvocationResponse) => {
                if (err) {
                    const error = {
                        ajaxError: 'lambda',
                        code: err.code,
                        message: err.message,
                    };

                    apiLog('FAILURE', functionName, error, true);

                    reject(error);
                } else {
                    apiLog('SUCCESS', functionName, res, true);

                    resolve(res);
                }
            },
        );
    });
};

const apiRequest = <T>({
    path,
    method,
    body,
    isSubscription,
    requestIsArray,
    lambdaFunction,
}: any): Observable<T> => {
    const user = localStorage.getItem('user');

    if (user && path !== '/login') {
        body.user = JSON.parse(user);
    }

    const modifiedBody = !requestIsArray
        ? {
              ...body,
          }
        : body;

    const settings = {
        method,
        url: `${Config.URL}${path}`,
        headers: { 'Content-Type': 'application/json' },
        responseType: 'json',
        body: modifiedBody,
    } as any;

    if (lambdaFunction) {
        return from(invokeLambda<T>(lambdaFunction, modifiedBody)) as any;
    }

    apiLog('REQUEST', path, modifiedBody);

    const token = localStorage.getItem('token');
    if (token && path !== '/login') {
        const Authorization = 'Authorization';
        settings.headers[Authorization] = `Bearer ${token}`;
    }
    if (isSubscription) {
        settings.headers['x-api-key'] = Config.ApiKey;
    }

    return ajax(settings).pipe(
        catchError(({ message, status, response }) => {
            const error: { message: string; code: string; errors: any[] } = {
                message,
                code: String(status),
                errors: [],
            };
            if (!!response) {
                error.errors = isArray(response) ? response : [response];
            }

            apiLog('FAILURE', path, error);

            return throwError(error);
        }),
        map(({ response }) => {
            apiLog('SUCCESS', path, response);

            return response;
        }),
    );
};

export const fetchApiRequestBlob = async (
    bodyRequest: any,
    path: string,
    configPath?: string,
): Promise<Blob | undefined> => {
    const settings = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: ['image/*', 'application/pdf', 'application/octet-stream'],
        },
        body: JSON.stringify(bodyRequest),
    } as any;

    const token = localStorage.getItem('token');
    if (token) {
        const Authorization = 'Authorization';
        settings.headers[Authorization] = `Bearer ${token}`;
    }

    consoleLog(`FO Data: POST ${path} ====> `, bodyRequest);
    try {
        const response = await fetch(
            `${configPath ? configPath : Config.URL}${path}`,
            settings,
        );
        consoleLog(`BO Response: POST ${path} ====> `, response);

        if (response.status === 200) {
            return response.blob();
        }

        return undefined;
    } catch (err) {
        consoleLog(`Error from ${path} (fetch) =>`, err);
        return undefined;
    }
};

export const fetchApiAsync = async (body: any, path: string): Promise<any> => {
    const settings = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    } as any;

    const token = localStorage.getItem('token');
    if (token) {
        const Authorization = 'Authorization';
        settings.headers[Authorization] = `Bearer ${token}`;
    }

    consoleLog(`FO Data: POST ${path} (fetch) ====> `, body);
    try {
        const response = await fetch(`${Config.URL}${path}`, settings);

        const res = await response.json();
        if (response.status >= 400) {
            const errors: any[] = isArray(res) ? res : [res];
            consoleLog(`Error from ${path} (fetch) =>`, res);
            return {
                code: response.status,
                description: response.statusText,
                errors,
            };
        }
        consoleLog(`BO Response: POST ${path} (fetch) ====> `, res);
        return res;
    } catch (err) {
        consoleLog(`Error from ${path} (fetch) =>`, err);
        let errors = [];
        if (!!err.response) {
            errors = isArray(err.response) ? err.response : [err.response];
        }
        return {
            errors,
            code: err.code,
            description: err.message,
        };
    }
};

export type APIPath =
    | '/resubmitCollection'
    | '/financialPiecesManagement'
    | '/processingLauncher'
    | '/createAdhocPayment'
    | '/manageCollection'
    | '/getPaymentIssues'
    | '/searchActivities'
    | '/createAdhocPayment'
    | '/logTracking'
    | '/manageAllocations'
    | '/searchContract'
    | '/financialPieces'
    | '/addLog'
    | '/uploadDocument'
    | '/getBalance'
    | '/createUser'
    | '/manageUser'
    | '/searchGroups'
    | '/manageThird'
    | '/createLetteringProposal'
    | '/letteringProposalManagement'
    | '/searchUserGroup'
    | '/changePassword'
    | '/resetPassword'
    | '/bankTransactionManagement';

export type AxiosRequestProps<TRequest> = {
    path: APIPath;
    payload: TRequest;
};

export const axiosRequest = <TRequest, TResponse>({
    path,
    payload,
}: AxiosRequestProps<TRequest>): Promise<TResponse> =>
    new Promise((resolve, reject) => {
        apiLog('REQUEST', path, payload);
        const token = localStorage.getItem('token');
        axios
            .post(path, addUserToBody(payload), {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                apiLog('SUCCESS', path, response.data);
                resolve(response.data);
            })
            .catch((err) => {
                apiLog(
                    'FAILURE',
                    path,
                    err.response && err.response.data ? err.response.data : err,
                );

                reject(
                    err.response && err.response.data ? err.response.data : err,
                );
            });
    });

export default apiRequest;
