import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthService from '../services/auth-service';
import UnauthorizedControl from './UnauthorizedControl';

const AuthGuard = ({ children }) => {
    const isSignIn = AuthService.isAuthenticated();

    if (!isSignIn) {
        return <Redirect to="/login" />;
    }

    return <UnauthorizedControl>{children}</UnauthorizedControl>;
};

export default AuthGuard;
