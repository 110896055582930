import { EErrorsType } from 'constants/enums';

import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as actions from '_actions/modules/payment/lettering.actions';
import { BankTransaction } from 'types/bank-transaction';

export type MonitoringState = Readonly<{
    resolvedTransactions: BankTransaction[];
    unresolvedTransactions: BankTransaction[];
    discardedTransactions: BankTransaction[];
    loading: boolean;
    error?: any;
}>;
const initialState = {
    resolvedTransactions: [],
    unresolvedTransactions: [],
    discardedTransactions: [],
    loading: false,
    error: undefined,
};
type Actions = actions.LetteringAction;
const letteringReducer: Reducer<MonitoringState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchBankTransactionAsync.request):
        case getType(actions.fetchReconcileFPAsync.request):
        case getType(actions.letteringProposalManagementAsync.request):
        case getType(actions.bankTransactionManagementAsync.request):
        case getType(actions.createLetteringProposalAsync.request): {
            return { ...state, loading: true };
        }
        case getType(actions.createLetteringProposalAsync.success): {
            console.log('response: ', action);
            return {
                ...state,
                loading: false,
                modalResponse: {
                    typeResponse: 'SUCCESS',
                    error: undefined,
                    message: action.payload,
                    actionType: action.type,
                },
            };
        }
        case getType(actions.createLetteringProposalAsync.failure): {
            return { ...state, loading: false };
        }
        case getType(actions.fetchBankTransactionAsync.success): {
            const { currentSection, transactions } = action.payload;
            switch (currentSection) {
                case 'RESOLVED':
                    return {
                        ...state,
                        resolvedTransactions: transactions,
                        error: undefined,
                        loading: false,
                    };
                case 'UNRESOLVED':
                    return {
                        ...state,
                        unresolvedTransactions: transactions,
                        error: undefined,
                        loading: false,
                    };
                case 'SPREAD_APART':
                    return {
                        ...state,
                        discardedTransactions: transactions,
                        error: undefined,
                        loading: false,
                    };
                default:
                    return { ...state };
            }
        }
        case getType(actions.fetchBankTransactionAsync.failure): {
            const { errType } = action.payload;
            switch (errType) {
                case EErrorsType.BANK_TRANSACTION_RESOLVED:
                    return {
                        ...state,
                        resolvedTransactions: [],
                        loading: false,
                        error: action.payload,
                    };
                case EErrorsType.BANK_TRANSACTION_UNRESOLVED:
                    return {
                        ...state,
                        unresolvedTransactions: [],
                        loading: false,
                        error: action.payload,
                    };
                case EErrorsType.BANK_TRANSACTION_DISCARDED:
                    return {
                        ...state,
                        discardedTransactions: [],
                        loading: false,
                        error: action.payload,
                    };
                default:
                    return { ...state, loading: false, error: action.payload };
            }
        }
        case getType(actions.fetchReconcileFPAsync.success): {
            return {
                ...state,
                loading: false,
                financialPieces: action.payload,
            };
        }
        case getType(actions.fetchReconcileFPAsync.failure):
            return {
                ...state,
                financialPieces: undefined,
                error: action.payload,
                loading: false,
            };
        case getType(actions.resetLetteringError): {
            return {
                ...state,
                error: '',
            };
        }
        default:
            return { ...state };
    }
};
export default letteringReducer;
