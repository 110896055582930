import { defaultPaginationReducer } from '../constants';

export default (state, payload, key) => {
    if ((payload as any).paginationLoading) {
        return {
            ...state,

            paginations: {
                ...state.paginations,

                [key]: {
                    ...(state as any).paginations[key],
                    paginationLoading: true,
                },
            },
        };
    }

    return {
        ...state,
        paginations: {
            ...state.paginations,
            [key]: defaultPaginationReducer,
        },
    };
};
