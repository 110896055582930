import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { getType } from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';

import { resetReduxStore } from '../_actions/auth.actions';
import services from '../services';
import rootEpic from './root-epic';
import rootReducer from './root-reducer';

const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState,
    Services
>({
    dependencies: services,
});

const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const prepareToStore = (str: RootState) => {
    return {
        ...str,

        authReducer: {
            ...str.authReducer,
            error: undefined,
        },

        settingReducer: {
            ...str.settingReducer,
            error: undefined,
            lastPayload: undefined,
            modalResponse: {
                error: undefined,
                typeResponse: 'REQUEST',
            },
        },
    };
};

const currentState = localStorage.getItem('reduxState');
const persistedState = currentState
    ? prepareToStore(JSON.parse(currentState))
    : {};

const middlewares = [epicMiddleware];

const reducer = (state: any, action: RootAction) => {
    if (action.type === getType(resetReduxStore)) {
        delete state.authReducer;
        delete state.settingReducer;
    }

    return rootReducer(state, action);
};

const store = createStore(
    reducer,
    persistedState,
    // initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
);

store.subscribe(() => {
    const state = store.getState();
    localStorage.setItem(
        'reduxState',
        JSON.stringify({
            authReducer: state.authReducer,
            settingReducer: state.settingReducer,
            contract_detail: state.contract_detail,
        }),
    );
});

epicMiddleware.run(rootEpic);

export default store;
