import { Customer } from 'types/customer';
import { ActionType, createAction } from 'typesafe-actions';

import {
    ADD_CUSTOMER_BY_CONTRACT,
    CLEAR_ALL,
    REMOVE_CUSTOMER_BY_CONTRACT,
} from './actionTypes';

const addCustomer = createAction(ADD_CUSTOMER_BY_CONTRACT)<{
    contractId: string;
    customer: Customer;
}>();

const removeCustomer = createAction(REMOVE_CUSTOMER_BY_CONTRACT)<{
    customerId: string;
}>();

const clearAll = createAction(CLEAR_ALL)<{
    customerId: string;
}>();

export type ContractDetailAction =
    | ActionType<typeof addCustomer>
    | ActionType<typeof removeCustomer>
    | ActionType<typeof clearAll>;

export { addCustomer, removeCustomer, clearAll };
