import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, isActionOf, ActionType } from 'typesafe-actions';
import { RootAction, RootState, Services, TModalErrorType } from 'Types';

import {
  CREATE_ADHOC_PAYMENT_REQUEST,
  CREATE_ADHOC_PAYMENT_FAILURE,
  CREATE_ADHOC_PAYMENT_SUCCESS,
} from './actionTypes';
import { API_CreateAdhocPaymentRequest } from 'api/payment';

const createAdhocPaymentAsync = createAsyncAction(
  CREATE_ADHOC_PAYMENT_REQUEST,
  CREATE_ADHOC_PAYMENT_SUCCESS,
  CREATE_ADHOC_PAYMENT_FAILURE,
)<API_CreateAdhocPaymentRequest, any, TModalErrorType>();

export type AdhocPaymentAction = ActionType<typeof createAdhocPaymentAsync>;

const prepareAdhocPayload = (payload: API_CreateAdhocPaymentRequest) => ({
  adhocPaymentList: payload,
});

const mapCreateAdhocPayment = (
  action: RootAction,
  { apiRequest }: Services,
) => {
  const payload = prepareAdhocPayload(action.payload);
  return apiRequest<any>({
    path: '/createAdhocPayment',
    method: 'post',
    body: payload,
  }).pipe(
    mergeMap((response: any) => {
      return of(createAdhocPaymentAsync.success(response));
    }),

    catchError((error) => of(createAdhocPaymentAsync.failure(error))),
  );
};

const createAdhocPaymentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, dependency) =>
  action$.pipe(
    filter(isActionOf(createAdhocPaymentAsync.request)),
    mergeMap((action) => mapCreateAdhocPayment(action, dependency)),
  );

export { createAdhocPaymentEpic, createAdhocPaymentAsync };
