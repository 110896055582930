import { PiecesAction } from './pieces.actions';
import { TransactionAction } from './transaction.actions';
import { AdhocPaymentAction } from './adhoc.actions';
import { CustomersAction } from './customer.actions';
import { BalanceAction } from './balance.actions';
import { MonitoringAction } from './monitoring.actions';
import { LetteringAction } from './lettering.actions';

export * from './pieces.actions';
export * from './transaction.actions';
export * from './adhoc.actions';
export * from './customer.actions';
export * from './balance.actions';
export * from './monitoring.actions';
export * from './lettering.actions';

export type PaymentAction =
    | PiecesAction
    | TransactionAction
    | AdhocPaymentAction
    | BalanceAction
    | CustomersAction
    | MonitoringAction
    | LetteringAction;
