export default (state, _, key) => {
    const nextState = { ...state };

    if (nextState.paginations[key]) {
        delete nextState.paginations[key];

        return {
            ...nextState,
        };
    }

    return {
        ...state,
    };
};
