import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
    Button,
    Collapse,
    ListItem,
    makeStyles,
    createStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) =>
    createStyles({
        item: {
            display: 'block',
            paddingTop: 0,
            paddingBottom: 0,
            fontWeight: 600,
        },
        itemLeaf: {
            display: 'flex',
            paddingTop: 0,
            paddingBottom: 0,
        },
        button: {
            color: theme.palette.text.secondary,
            padding: '10px 8px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            letterSpacing: 0,
            width: '100%',
        },
        buttonLeaf: {
            color: theme.palette.text.secondary,
            padding: '10px 8px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            letterSpacing: 0,
            width: '100%',
            fontWeight: theme.typography.fontWeightRegular as any,
            '&.depth-0': {
                '& $title': {
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(1),
        },
        title: {
            marginRight: 'auto',
        },
        active: {
            color: theme.palette.secondary.main,
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium,
            },
            '& $icon': {
                color: theme.palette.secondary.main,
            },
        },
        info: {},
    }),
);

type NavItemProps = {
    children?: React.ReactNode;
    className?: string;
    depth: number;
    href?: string;
    icon: any;
    info: any;
    open?: boolean;
    title: string;
};

const NavItem: React.FC<NavItemProps> = ({
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp = false,
    info: Info,
    ...rest
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(openProp);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = { paddingLeft };

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title}
                {...rest}
            >
                <Button
                    className={classes.button}
                    onClick={handleToggle}
                    style={style}
                >
                    {Icon && <Icon className={classes.icon} size="20" />}
                    <span className={classes.title}>{title}</span>
                    {open ? (
                        <ExpandLessIcon color="inherit" />
                    ) : (
                        <ExpandMoreIcon color="inherit" />
                    )}
                </Button>
                <Collapse in={open}>{children}</Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title}
            {...rest}
        >
            <Button
                activeClassName={classes.active}
                className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                component={RouterLink}
                exact
                style={style}
                to={href || '/'}
            >
                {Icon && <Icon className={classes.icon} size="20" />}
                <span className={classes.title}>{title}</span>
                {Info && <Info className={classes.info} />}
            </Button>
        </ListItem>
    );
};

export default NavItem;
