import { ReducerError, APIType, UserWatchRequest, TModalResponse } from 'Types';

import { AuthState } from '../_reducers/auth.reducer';
import { EPiecesType } from '../constants/enums';
import config from './config';
import resources from './resources';

const consoleLog = (title: string, message: any, ...style: string[]) => {
    if (config.ENV === 'PREPRODUCTION') {
        console.log(`${title}`, ...style, message);
    }
};

const getColorByType = (type: APIType) => {
    switch (type) {
        case 'REQUEST':
            return '#f5a623';
        case 'SUCCESS':
            return '#34b026';
        case 'FAILURE':
            return '#ff455c';
        default:
            return 'fff';
    }
};

const renderStyleLogByType = (type: APIType) => {
    const currentColor = getColorByType(type);
    return [
        `color:${currentColor}; font-weight: bold;`,
        `color:${currentColor};`,
        `color:${currentColor}; text-decoration: underline; font-weight: bold;`,
        `color:${currentColor};`,
    ];
};

const apiLog = <T>(
    type: APIType,
    path: string,
    logM: string | object | any[] | T,
    isLambda?: boolean,
) => {
    const chackLambda = () => (isLambda ? 'lambda' : 'API');
    switch (type) {
        case 'REQUEST':
            return consoleLog(
                `%cFO %cData:${chackLambda()}: %c${path}%c ====> `,
                logM,
                ...renderStyleLogByType(type),
            );

        case 'SUCCESS':
            return consoleLog(
                `%cBO %cResponse:${chackLambda()} %c${path}%c ====> `,
                logM,
                ...renderStyleLogByType(type),
            );

        case 'FAILURE':
            return consoleLog(
                `%cError %cfrom ${chackLambda()}: %c${path}%c ====> `,
                logM,
                ...renderStyleLogByType(type),
            );
        default:
            break;
    }
};

const pad = (s: number) => {
    return s < 10 ? `0${s}` : s;
};

const getFormattedDate = (date?: string): string => {
    if (!date) {
        return '';
    }
    const d = new Date(date);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
};

const getFormattedDateToBackEnd = (d: Date | undefined): string | undefined => {
    if (d !== undefined) {
        return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join(
            '-',
        );
    }
    return undefined;
};

const getTodayDate = (): string => {
    const d = new Date();
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
};

const getLabelByValue = (
    value: string,
    arr: Array<{
        value: string;
        label: string;
    }>,
): string => {
    const objFind = (arr &&
        arr.find(
            (obj: { value: string; label: string }) => obj.value === value,
        )) || {
        value: '',
        label: '',
    };
    return objFind.label;
};

const isOdd = (num: number): boolean => Math.abs(num % 2) === 1;

const searchLabelByData = (val: string, data: any[]) => {
    const obj = data.find((elm: any) => elm.value === val);
    return obj ? obj.label : '';
};

const searchResource = (s: string) => {
    switch (s) {
        case 'in':
            return resources['ic-invoice'];
        case 'pp':
            return resources['ic-prepayment'];
        case 'adhocPieces':
            return resources['ic-adhoc'];
        case 'directDebit':
            return resources['ic-direct-debit'];
        case 'bankTransfer':
            return resources['ic-bank-transfer'];
        case 'bankCard':
            return resources['ic-bank-card'];
        case 'cash':
            return resources['ic-cash'];
        case 'energyCheck':
            return resources['ic-cheque'];
        case 'check':
            return resources['ic-cheque'];
        default:
            return null;
    }
};

const isValidEmail = (value?: string): boolean => {
    if (value === undefined) {
        return false;
    }
    const regex =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(value).toLowerCase());
};

const isValidPhone = (value?: string) => {
    if (value === undefined) {
        return false;
    }

    const regex = /^((06)|(07))[0-9]{8}$/;
    return regex.test(String(value));
};

const isEmpty = (arr: any[]) => arr && arr.length === 0;

const returnError = (err?: ReducerError, errT?: any) => {
    return err && err.errType === errT ? err.error : undefined;
};

const currentListPagination = (
    currentPage: number,
    pageLimit: number,
    arrList: any[],
    callback: (arr: any[]) => void,
) => {
    const offset = (currentPage - 1) * pageLimit;
    return callback(arrList.slice(offset, offset + pageLimit));
};

const removeDuplicates = (array: any[], key: string) => {
    return array.reduce((accumulator, element) => {
        if (!accumulator.find((el: any) => el[key] === element[key])) {
            accumulator.push(element);
        }
        return accumulator;
    }, []);
};

const compareSortSelect = (a: any, b: any) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0;

const removeParam = (key: string, sourceURL: string) => {
    let rtn = sourceURL.split('?')[0],
        param,
        params_arr: any[] = [],
        queryString =
            sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
        params_arr = queryString.split('&');
        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split('=')[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + '?' + params_arr.join('&');
    }
    return rtn;
};

const isJson = (str: string): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

const buildClassName = (...args: string[]) => args.join('__');

const formatBytes = (a: number, b?: number) => {
    if (0 === a) return '0 Bytes';
    const c = 1024,
        d = b || 2,
        e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
};

const fixedDecimal = (value: number, decimalPlaces: number): number => {
    return Number(
        Math.round(parseFloat(value + 'e' + decimalPlaces)) +
            'e-' +
            decimalPlaces,
    );
};

const getEnumPieces = (type: string) => {
    switch (type) {
        case 'invoices':
            return EPiecesType.INVOICE;

        case 'prepayments':
            return EPiecesType.PREPAYMENT;

        case 'adhocPieces':
            return EPiecesType.ADHOC;

        default:
            return EPiecesType.INVOICE;
    }
};

const redirectByPieces = (
    callback: React.Dispatch<React.SetStateAction<EPiecesType>>,
) => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');
    if (type) {
        switch (type) {
            case 'invoices':
                callback(EPiecesType.INVOICE);
                break;

            case 'prepayments':
                callback(EPiecesType.PREPAYMENT);
                break;

            case 'adhocPieces':
                callback(EPiecesType.ADHOC);
                break;

            default:
                break;
        }
    }
};

const isDate = (date: any) => typeof (date as any).getMonth === 'function';

const getISODate = (date: string | Date) => {
    if (isDate(date)) {
        return new Date(
            getFormattedDateToBackEnd(date as Date) || '',
        ).toISOString();
    }

    return new Date(date).toISOString();
};

const isNumeric = (x: any) =>
    !isNaN(x) &&
    typeof x !== 'object' &&
    x !== Number.POSITIVE_INFINITY &&
    x !== Number.NEGATIVE_INFINITY;

const getInitialGroup = (group: string) => group.split('_')[0];

const getGroupSubGroup = (
    grp: string,
): {
    group: string;
    subGroup: string;
} => {
    const arr = grp.split('_');

    return {
        group: arr[0] || '',
        subGroup: arr[1] || '',
    };
};

const prepareUserWatchRequest = ({
    user: { userName, group, subGroup },
}: AuthState): UserWatchRequest => ({
    user: {
        userGroupe: group,
        userName,
        userSubGroup: subGroup,
    },
});

const buildGroupName = (group: string, subGroup: string): string =>
    subGroup !== '' ? `${group}_${subGroup}` : group;

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let colour = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return colour;
}

const getMessageSnackbar = (
    modalResponse: TModalResponse,
    defaultMessage: string,
) => {
    if (modalResponse.message && typeof modalResponse.message === 'string') {
        return modalResponse.message;
    }

    if (
        modalResponse.message &&
        typeof modalResponse.message === 'object' &&
        (modalResponse.message as any).message
    ) {
        return (modalResponse.message as any).message;
    }

    return defaultMessage;
};

const getInitials = (name = '') =>
    name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map((v) => v && v[0].toUpperCase())
        .join('');

const bytesToSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const controlValue = (val: any) => (val && val !== '' ? val : '-');

const addUserToBody = (body: any) => {
    const user = localStorage.getItem('user');

    if (user) {
        body.user = JSON.parse(user);
    }

    return body;
};

export {
    currentListPagination,
    addUserToBody,
    controlValue,
    getInitialGroup,
    buildGroupName,
    bytesToSize,
    getMessageSnackbar,
    getInitials,
    isNumeric,
    searchLabelByData,
    stringToColor,
    getGroupSubGroup,
    prepareUserWatchRequest,
    compareSortSelect,
    formatBytes,
    getISODate,
    getFormattedDate,
    redirectByPieces,
    getEnumPieces,
    isJson,
    buildClassName,
    removeParam,
    removeDuplicates,
    returnError,
    getFormattedDateToBackEnd,
    getTodayDate,
    getLabelByValue,
    isValidPhone,
    isValidEmail,
    consoleLog,
    isEmpty,
    isOdd,
    fixedDecimal,
    apiLog,
    searchResource,
};
