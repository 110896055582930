import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { Customer } from 'types/customer';
import { Perimeter } from 'types/perimeter';
import {
    findPerimeterByContractId,
    getPayerDataByCustomer,
    PayerData,
} from 'utils/modules/payment-functions';

import {
    resetCustomers,
    CustomersAction,
} from '_actions/modules/payment/customer.actions';

import * as actions from '_actions/common/contract-detail.actions';

type ContractDetailData = {
    [key: string]: {
        customer: Customer;
        perimeter?: Perimeter;
        payerData?: PayerData;
    };
};

export type ContractsState = Readonly<{
    contractsIds: Array<string>;
    data: ContractDetailData;
}>;

const initialState: ContractsState = {
    contractsIds: [],
    data: {},
};

type Actions = actions.ContractDetailAction | CustomersAction;

const contractsReducer: Reducer<ContractsState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.addCustomer): {
            const { customer, contractId } = action.payload;

            if (state.contractsIds.includes(contractId))
                return {
                    ...state,
                };

            const perimeter = findPerimeterByContractId(
                customer.perimeters,
                contractId,
            );

            const payerData = getPayerDataByCustomer(contractId, customer);

            return {
                ...state,
                contractsIds: [...state.contractsIds, contractId],
                data: {
                    ...state.data,
                    [contractId]: {
                        customer,
                        payerData,
                        perimeter,
                    },
                },
            };
        }

        case getType(resetCustomers): {
            return {
                ...initialState,
            };
        }

        default:
            return { ...state };
    }
};

export default contractsReducer;
