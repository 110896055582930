import { createContext } from 'react';

import { Pagination } from 'types/pagination';

import { defaultPagination } from '../constants';

export type PaginationContextProps = {
    loading?: boolean;
    request: () => void;
    setRequest: (fn: (payload: any) => void, payload: any) => void;
    enabled: boolean;
    paginationLoading: boolean;

    pagination: Pagination;
};

export type PaginationsContextProps = PaginationContextProps & {
    setCurrentKey: (key: string) => void;
    setRequest: (fn: (payload: any) => void, payload: any, key: string) => void;
};

const PaginationContext = createContext<PaginationContextProps>({
    pagination: defaultPagination,
    request: () => {},
    setRequest: () => {},
    enabled: false,
    paginationLoading: false,
});

const PaginationsContext = createContext<PaginationsContextProps>({
    pagination: defaultPagination,
    request: () => {},
    setRequest: () => {},
    enabled: false,
    paginationLoading: false,
    setCurrentKey: () => {},
    loading: false,
});

export { PaginationContext, PaginationsContext };
