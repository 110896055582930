import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { TError } from 'Types';

import * as actions from '_actions/modules/payment/revival.actions';

import { TActivity } from 'modules/payment';
import { TLog } from 'modules/customer-care';

export type TLogsReducer = Array<{
    eventId: string;
    eventLogs: TLog[];
    error?: TError;
}>;

export type MonitoringState = Readonly<{
    activitiesList: TActivity[];
    logs: TLogsReducer;
    loading: boolean;
    error: any;
}>;

const initialState = {
    activitiesList: [],
    logs: [],
    loading: false,
    error: undefined,
};

type Actions = actions.RevivalAction;

const revivalReducer: Reducer<MonitoringState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchActivitiesAsync.request): {
            return {
                ...initialState,
                loading: true,
            };
        }
        case getType(actions.fetchActivitiesAsync.success): {
            return {
                ...state,
                ...action.payload,
                loading: false,
                error: undefined,
                logs: [],
            };
        }

        case getType(actions.fetchActivitiesAsync.failure): {
            return {
                ...state,
                activitiesList: [],
                loading: false,
                error: action.payload,
                logs: [],
            };
        }

        case getType(actions.resetActivityList): {
            return {
                ...state,
                activitiesList: [],
            };
        }
        default:
            return { ...state };
    }
};

export default revivalReducer;
