import React from 'react';
import resources from '../utils/resources';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        logo: {
            width: '42px',
            height: '42px',
        },
    };
});

const Logo = (props) => {
    const classes = useStyles();
    return (
        <img
            className={classes.logo}
            alt="Logo"
            src={resources['ic-kiwi-pay']}
            {...props}
        />
    );
};

export default Logo;
