import { Pagination } from 'types/pagination';

const defaultPagination: Pagination = {
    limit: 50,
    offset: 0,
    resultSet: 0,
    total: 0,
};

const defaultPaginationReducer = {
    data: defaultPagination,
    paginationLoading: false,
};

export { defaultPagination, defaultPaginationReducer };
