import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createAction,
} from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';

import {
    SearchCustomerRequest,
    SearchCustomerResponse,
} from 'api-types/search-customer';

import {
    FETCH_CUSTOMERS_REQUEST,
    FETCH_CUSTOMERS_FAILURE,
    FETCH_CUSTOMERS_SUCCESS,
    RESET_CUSTOMERS,
} from './actionTypes';
import { WithPaginationLoading } from 'types/pagination';
import { addPaginationToPayload } from 'utils/helpers/pagination';

type SearchCustomerActionPayload = WithPaginationLoading<SearchCustomerRequest>;

const fetchCustomersAsync = createAsyncAction(
    FETCH_CUSTOMERS_REQUEST,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAILURE,
)<SearchCustomerActionPayload, SearchCustomerResponse, any>();

const resetCustomers = createAction(RESET_CUSTOMERS)();

export type CustomerAction =
    | ActionType<typeof fetchCustomersAsync>
    | ActionType<typeof resetCustomers>;

const preparePayloadSearchCustomer = ({
    paginationLoading,
    ...payload
}: SearchCustomerActionPayload): SearchCustomerRequest =>
    addPaginationToPayload({
        ...payload,
    });

const mapFetchCustomers = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadSearchCustomer(action.payload);
    return apiRequest<any>({
        path: '/searchCustomer',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any) => {
            return of(fetchCustomersAsync.success(response));
        }),

        catchError((error) => {
            return of(fetchCustomersAsync.failure(error));
        }),
    );
};

const fetchCustomerEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchCustomersAsync.request)),
        mergeMap((action) => mapFetchCustomers(action, dependency)),
    );

export {
    fetchCustomerEpic,
    fetchCustomersAsync,
    mapFetchCustomers,
    resetCustomers,
};
