import { Reducer } from 'redux';
import { Entity } from 'types/entity';
import { getType } from 'typesafe-actions';

import * as actions from '_actions/modules/payment/entity.actions';

export type CustomersState = Readonly<{
    entities: Entity[];
    loading: boolean;

    error?: any;
}>;

const initialState = {
    entities: [],
    loading: false,

    error: undefined,
};

type Actions = actions.EntityActions;

const entityReducer: Reducer<CustomersState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.searchEntitiesAsync.request): {
            return {
                ...state,
                loading: true,
            };
        }

        case getType(actions.searchEntitiesAsync.success): {
            const { entities } = action.payload;
            return {
                ...state,
                entities,
                loading: false,
            };
        }

        case getType(actions.searchEntitiesAsync.failure): {
            return {
                ...state,
                entities: [],
                error: action.payload,
                loading: false,
            };
        }

        default:
            return { ...state };
    }
};

export default entityReducer;
