import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetReduxStore } from '../_actions/auth.actions';
import AuthService from '../services/auth-service';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Slide,
    DialogContentText,
    Button,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UnauthorizedControl: React.FC = ({ children }) => {
    const [t] = useTranslation('auth');

    const dispatch = useDispatch();
    const history = useHistory();

    const logout = useCallback(() => {
        dispatch(resetReduxStore());
        AuthService.logout();
        history.push('/login');
    }, [dispatch, history]);

    const isAuth = AuthService.checkTokenExpire();

    return (
        <>
            {children}
            <Dialog
                open={!isAuth}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => logout()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {t('expiredSession.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('expiredSession.description')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => logout()}
                        color="primary"
                        endIcon={<ExitToAppIcon />}
                    >
                        {t('expiredSession.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UnauthorizedControl;
