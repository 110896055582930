export const FETCH_FINANCIAL_PIECES_REQUEST =
    '@payment/fetch-financial-pieces-request';
export const FETCH_FINANCIAL_PIECES_FAILURE =
    '@payment/fetch-financial-pieces-failure';
export const FETCH_FINANCIAL_PIECES_SUCCESS =
    '@payment/fetch-financial-pieces-success';

export const UPDATE_CUSTOMER_THIRD = '@payment/update-customer-third';

export const FETCH_ONE_PIECES_REQUEST = '@payment/fetch-one-piece-request';
export const FETCH_ONE_PIECES_FAILURE = '@payment/fetch-one-piece-failure';
export const FETCH_ONE_PIECES_SUCCESS = '@payment/fetch-one-piece-success';

export const REPLACE_RESUBMIT_TRANSACTION = 'REPLACE_RESUBMIT_TRANSACTION';

export const INIT_PIECES_LIST = '@payment/reset-pieces-list';
export const INIT_CUSTOMER_LIST = '@payment/reset-customer-list';

export const RESET_LETTERING_ERROR = '@payment/reset-lettering-error';

export const RESET_ACTIVITY_LIST = '@payment/reset-activity-list';
export const RESET_MONITORING_LIST = '@payment/reset-monitoring-list';
export const RESET_SYNCHRONIZATION_MONITORING_LIST =
    '@payment/reset-synchronization-monitoring-list';
export const RESET_TRANSACTIONAL_RELEASE_MONITORING_LIST =
    '@payment/reset-transactional-release-monitoring-list';

export const CREATE_ADHOC_PAYMENT_REQUEST =
    '@payment/create-adhoc-payment-request';
export const CREATE_ADHOC_PAYMENT_FAILURE =
    '@payment/create-adhoc-payment-failure';
export const CREATE_ADHOC_PAYMENT_SUCCESS =
    '@payment/create-adhoc-payment-success';

export const FETCH_CUSTOMER_CONTRACT_REQUEST =
    '@payment/fetch-customer-contract-request';
export const FETCH_CUSTOMER_CONTRACT_FAILURE =
    '@payment/fetch-customer-contract-failure';
export const FETCH_CUSTOMER_CONTRACT_SUCCESS =
    '@payment/fetch-customer-contract-success';

export const FETCH_CUSTOMER_FINANCIAL_PIECES_REQUEST =
    '@payment/fetch-customer-fp-request';
export const FETCH_CUSTOMER_FINANCIAL_PIECES_FAILURE =
    '@payment/fetch-customer-fp-failure';
export const FETCH_CUSTOMER_FINANCIAL_PIECES_SUCCESS =
    '@payment/fetch-customer-fp-success';

export const FETCH_CUSTOMER_ONE_FINANCIAL_PIECES_REQUEST =
    '@payment/fetch-customer-one-fp-request';
export const FETCH_CUSTOMER_ONE_FINANCIAL_PIECES_FAILURE =
    '@payment/fetch-customer-one-fp-failure';
export const FETCH_CUSTOMER_ONE_FINANCIAL_PIECES_SUCCESS =
    '@payment/fetch-customer-one-fp-success';

export const FETCH_BALANCE_SUCCESS = '@payment/fetch-balance-success';
export const FETCH_BALANCE_REQUEST = '@payment/fetch-balance-request';
export const FETCH_BALANCE_FAILURE = '@payment/fetch-balance-failure';

export const FETCH_ACTIVITIES_REQUEST = '@payment/fetch-activities-request';
export const FETCH_ACTIVITIES_FAILURE = '@payment/fetch-activities-failure';
export const FETCH_ACTIVITIES_SUCCESS = '@payment/fetch-activities-success';

export const FETCH_MONITORING_REQUEST = '@payment/fetch-monitoring-request';
export const FETCH_MONITORING_SUCCESS = '@payment/fetch-monitoring-success';
export const FETCH_MONITORING_FAILURE = '@payment/fetch-monitoring-failure';

export const MONITORING_ACTIONS_REQUEST = '@payment/monitoring-actions-request';
export const MONITORING_ACTIONS_FAILURE = '@payment/monitoring-actions-failure';
export const MONITORING_ACTIONS_SUCCESS = '@payment/monitoring-actions-success';

export const SEARCH_USER_GROUP_FAILURE = '&payment/search-user-group-failure';
export const SEARCH_USER_GROUP_SUCCESS = '&payment/search-user-group-success';
export const SEARCH_USER_GROUP_REQUEST = '&payment/search-user-group-request';

export const CREATE_USER_FAILURE = '@payment/create-user-failure';
export const CREATE_USER_SUCCESS = '@payment/create-user-success';
export const CREATE_USER_REQUEST = '@payment/create-user-request';

export const MANAGE_USER_REQUEST = '@payment/manage-user-request';
export const MANAGE_USER_FAILURE = '@payment/manage-user-failure';
export const MANAGE_USER_SUCCESS = '@payment/manage-user-success';

export const FETCH_GROUPS_REQUEST = '@payment/fetch-groups-request';
export const FETCH_GROUPS_FAILURE = '@payment/fetch-groups-failure';
export const FETCH_GROUPS_SUCCESS = '@payment/fetch-groups-success';

export const CREATE_GROUP_REQUEST = '@payment/crete-group-request';
export const CREATE_GROUP_FAILURE = '@payment/crete-group-failure';
export const CREATE_GROUP_SUCCESS = '@payment/crete-group-success';

export const SEARCH_ENTITIES_REQUEST = '@payment/search-entities-request';
export const SEARCH_ENTITIES_SUCCESS = '@payment/search-entities-success';
export const SEARCH_ENTITIES_FAILURE = '@payment/search-entities-failure';

export const FETCH_BANK_TRANSACTION_REQUEST =
    '@payment/fetch-bank-transaction-request';
export const FETCH_BANK_TRANSACTION_SUCCESS =
    '@payment/fetch-bank-transaction-success';
export const FETCH_BANK_TRANSACTION_FAILURE =
    '@payment/fetch-bank-transaction-failure';

export const FETCH_RECONCILE_FINANCIAL_PIECES_REQUEST =
    '@payment/fetch-reconcile-financial-pieces-request';
export const FETCH_RECONCILE_FINANCIAL_PIECES_SUCCESS =
    '@payment/fetch-reconcile-financial-pieces-success';
export const FETCH_RECONCILE_FINANCIAL_PIECES_FAILURE =
    '@payment/fetch-reconcile-financial-pieces-failure';

export const LETTERING_PROPOSAL_MANAGEMENT_REQUEST =
    '@payment/lettering-proposal-management-request';
export const LETTERING_PROPOSAL_MANAGEMENT_SUCCESS =
    '@payment/lettering-proposal-management-success';
export const LETTERING_PROPOSAL_MANAGEMENT_FAILURE =
    '@payment/lettering-proposal-management-failure';

export const BANK_TRANSACTION_MANAGEMENT_REQUEST =
    '@payment/bank-transaction-management-request';
export const BANK_TRANSACTION_MANAGEMENT_SUCCESS =
    '@payment/bank-transaction-management-success';
export const BANK_TRANSACTION_MANAGEMENT_FAILURE =
    '@payment/bank-transaction-management-failure';

export const CREATE_LETTERING_PROPOSAL_REQUEST =
    '@payment/create-lettering-proposal-request';
export const CREATE_LETTERING_PROPOSAL_SUCCESS =
    '@payment/create-lettering-proposal-success';
export const CREATE_LETTERING_PROPOSAL_FAILURE =
    '@payment/create-lettering-proposal-failure';
