import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { TBuildRoute } from 'Types';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256,
        },
    },
    wrapperClose: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        padding: '10px',
    },
}));

const DashboardLayout: React.FC<{ routes: TBuildRoute[] }> = ({
    children,
    routes,
}) => {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(true);

    return (
        <div className={classes.root}>
            <TopBar
                onMobileNavOpen={() => setMobileNavOpen(true)}
                toggleDrawer={() => setDrawerOpen(!isDrawerOpen)}
            />
            <NavBar
                routes={routes}
                onMobileClose={() => setMobileNavOpen(false)}
                isDrawerOpen={isDrawerOpen}
                openMobile={isMobileNavOpen}
            />
            <div
                className={
                    isDrawerOpen ? classes.wrapper : classes.wrapperClose
                }
            >
                <div className={classes.contentContainer}>
                    <div className={classes.content}>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;
