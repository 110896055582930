/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment, useMemo } from 'react';

import { TAuthorizationSchema } from 'Models';
import { Switch, Redirect, Route } from 'react-router-dom';

import { TBuildRoute } from 'Types';

import LoadingScreen from './components/LoadingScreen';
import DashboardLayout from './layouts/DashboardLayout';
import AuthGuard from './libraries/AuthGuard';
import GuestGuard from './libraries/GuestGuard';

const routesConfig = {
    public: [
        {
            exact: true,
            guard: GuestGuard,
            path: '/login',
            component: lazy(() => import('./pages/AuthPage')),
        },
        {
            exact: true,
            path: '/404',
            component: () => {
                return (
                    <>
                        <p>404 Not found!</p>
                    </>
                );
            },
        },
    ],
    notFound: {
        path: '*',
        routes: [
            {
                component: () => <Redirect to="/login" />,
            },
        ],
    },
    paymentManagement: {
        path: '/payment',
        guard: AuthGuard,
        layout: DashboardLayout,
        isModule: true,
        routes: {
            public: [
                {
                    exact: true,
                    path: '/payment',
                    component: () => <Redirect to="/payment/dashboard" />,
                },
            ],
            notFound: {
                component: () => <Redirect to="/404" />,
            },
            dashboard: {
                exact: true,
                path: '/payment/dashboard',
                component: () => {
                    return (
                        <>
                            <p>Dashboard</p>
                        </>
                    );
                },
            },

            search: {
                exact: true,
                path: '/payment/search',
                component: lazy(() => import('./views/modules/Payment/Search')),
                routes: [
                    {
                        exact: true,
                        path: '/payment/search/contract/:contractId',
                        component: lazy(
                            () =>
                                import(
                                    './views/modules/common/ContractDetailsView'
                                ),
                        ),
                    },

                    {
                        exact: true,
                        path: '/payment/search/contract/:contractId/:posId',
                        component: lazy(
                            () =>
                                import(
                                    './views/modules/CustomerCare/pointOfService/PointOfServiceView'
                                ),
                        ),
                    },
                ],
            },

            revival: {
                exact: true,
                path: '/payment/revival',
                component: lazy(
                    () => import('./views/modules/Payment/Revival'),
                ),
            },
            monitoring: {
                exact: true,
                path: '/payment/monitoring',
                component: lazy(
                    () => import('./views/modules/Payment/Monitoring'),
                ),
            },
            lettering: {
                exact: true,
                path: '/payment/lettering',
                component: lazy(
                    () => import('./views/modules/Payment/Lettering'),
                ),
            },
            user: {
                exact: true,
                path: '/payment/users',
                component: lazy(
                    () => import('./views/modules/common/User/UserListView'),
                ),
                routes: [
                    {
                        exact: true,
                        path: '/payment/user/:userName/edit',
                        component: lazy(
                            () => import('./views/modules/Payment/Profile'),
                        ),
                    },

                    {
                        exact: true,
                        path: '/payment/user/:userName',
                        component: lazy(
                            () =>
                                import(
                                    './views/modules/common/User/UserDetailsView'
                                ),
                        ),
                    },
                ],
            },
            profile: {
                exact: true,
                path: '/payment/profile',
                component: lazy(
                    () => import('./views/modules/common/User/ProfileView'),
                ),
            },
        },
    },

    customerCare: {
        path: '/customer-care',
        guard: AuthGuard,
        layout: DashboardLayout,
        isModule: true,
        routes: {
            public: [
                {
                    exact: true,
                    path: '/customer-care',
                    component: () => <Redirect to="/customer-care/dashboard" />,
                },
            ],
            notFound: {
                component: () => <Redirect to="/404" />,
            },
            dashboard: {
                exact: true,
                path: '/customer-care/dashboard',
                component: () => {
                    return (
                        <>
                            <p>Dashboard</p>
                        </>
                    );
                },
            },
            customers: {
                exact: true,
                path: '/customer-care/customers',
                component: lazy(
                    () =>
                        import(
                            './views/modules/CustomerCare/customer/CustomerListView'
                        ),
                ),
                routes: [
                    {
                        exact: true,
                        path: '/customer-care/customers/:customerId',
                        component: lazy(
                            () =>
                                import(
                                    './views/modules/CustomerCare/customer/CustomerDetailsView'
                                ),
                        ),
                    },

                    {
                        exact: true,
                        path: '/customer-care/customers/:customerId/perimeter/:perimeterId',
                        component: lazy(
                            () =>
                                import(
                                    'views/modules/CustomerCare/perimetre/PerimeterDetailsView'
                                ),
                        ),
                    },
                ],
            },
            contracts: {
                exact: true,
                path: '/customer-care/contracts',
                component: lazy(
                    () =>
                        import(
                            'views/modules/CustomerCare/contract/ContractListView'
                        ),
                ),
                routes: [
                    {
                        exact: true,
                        path: '/customer-care/contracts/:contractId',
                        component: lazy(
                            () =>
                                import(
                                    './views/modules/common/ContractDetailsView'
                                ),
                        ),
                    },

                    {
                        exact: true,
                        path: '/customer-care/contracts/:contractId/:posId',
                        component: lazy(
                            () =>
                                import(
                                    './views/modules/CustomerCare/pointOfService/PointOfServiceView'
                                ),
                        ),
                    },
                ],
            },
            orders: {
                exact: true,
                path: '/customer-care/orders',
                component: () => {
                    return (
                        <>
                            <p>orders</p>
                        </>
                    );
                },
            },
        },
    },
};

const buildRoutes = (authorizationScheme: any[]): TBuildRoute[] => {
    const routes: any[] = [...routesConfig.public];

    authorizationScheme.forEach((asc) => {
        const moduleConfig = routesConfig[asc.moduleId];
        const newSubRoutes = [...moduleConfig.routes.public];

        asc.menu.forEach((mm) => {
            const subMenuConfig = moduleConfig.routes[mm.rootName];

            if (subMenuConfig) {
                newSubRoutes.push({
                    exact: subMenuConfig.exact,
                    path: subMenuConfig.path,
                    component: subMenuConfig.component,
                    ...mm,
                });

                if (subMenuConfig.routes) {
                    subMenuConfig.routes.forEach((r) => {
                        newSubRoutes.push(r);
                    });
                }
            }
        });

        newSubRoutes.push({ ...moduleConfig.routes.notFound });

        routes.push({
            ...moduleConfig,
            id: asc.id,
            label: asc.label,
            moduleId: asc.moduleId,
            routes: newSubRoutes,
        });
    });

    routes.push({ ...routesConfig.notFound });

    return routes;
};

const renderRoutes = (routes) =>
    routes ? (
        <Suspense fallback={<LoadingScreen />}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Component = route.component;

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => {
                                const renderComponent = route.routes ? (
                                    renderRoutes(route.routes)
                                ) : (
                                    <Component {...props} />
                                );
                                return (
                                    <Guard>
                                        {route.layout ? (
                                            <route.layout routes={routes}>
                                                {renderComponent}
                                            </route.layout>
                                        ) : (
                                            <>{renderComponent}</>
                                        )}
                                    </Guard>
                                );
                            }}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    ) : null;

const Routes: React.FC<{
    authorizationScheme: TAuthorizationSchema[];
}> = ({ authorizationScheme }) => {
    const routes = useMemo(
        () => buildRoutes(authorizationScheme),
        [authorizationScheme],
    );

    return renderRoutes(routes);
};

export default React.memo(Routes);
