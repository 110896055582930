import React, { useState, useRef } from 'react';
import { capitalCase } from 'change-case';
import {
    Badge,
    Box,
    Button,
    IconButton,
    Popover,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';

import { EThemes, ELanguage } from '../../../constants/enums';
import useSettings from '../../../hooks/useSettings';
import { TSettings } from '../../../contexts/SettingsContext';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5,
    },
    popover: {
        width: 320,
        padding: theme.spacing(2),
    },
}));

const Settings = () => {
    const [t, i18n] = useTranslation('header/settings');
    const classes = useStyles();
    const ref = useRef(null);
    const { settings, saveSettings } = useSettings();

    const [isOpen, setOpen] = useState<boolean>(false);
    const [values, setValues] = useState<TSettings>({
        theme: settings.theme,
        language: settings.language,
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (field, value) => {
        setValues({
            ...values,
            [field]: value,
        });
    };

    const handleSave = () => {
        saveSettings(values);
        setOpen(false);
        i18n.changeLanguage(values.language.toLowerCase());
    };

    const title = t('title');

    return (
        <>
            <Tooltip title={title}>
                <Badge
                    color="secondary"
                    variant="dot"
                    classes={{ badge: classes.badge }}
                >
                    <IconButton color="inherit" onClick={handleOpen} ref={ref}>
                        <SvgIcon fontSize="small">
                            <SettingsIcon />
                        </SvgIcon>
                    </IconButton>
                </Badge>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Typography variant="h4" color="textPrimary">
                    {title}
                </Typography>

                <Box mt={2}>
                    <TextField
                        fullWidth
                        label={t('theme')}
                        name="theme"
                        onChange={(event) =>
                            handleChange('theme', event.target.value)
                        }
                        select
                        SelectProps={{ native: true }}
                        value={values.theme}
                        variant="outlined"
                    >
                        {Object.keys(EThemes).map((theme) => (
                            <option key={theme} value={theme}>
                                {capitalCase(theme)}
                            </option>
                        ))}
                    </TextField>
                </Box>

                <Box mt={2}>
                    <TextField
                        fullWidth
                        label={t('language')}
                        name="language"
                        onChange={(event) =>
                            handleChange('language', event.target.value)
                        }
                        select
                        SelectProps={{ native: true }}
                        value={values.language}
                        variant="outlined"
                    >
                        {Object.keys(ELanguage).map((language) => (
                            <option key={language} value={language}>
                                {capitalCase(language)}
                            </option>
                        ))}
                    </TextField>
                </Box>
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={handleSave}
                    >
                        {t('btn_save')}
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default Settings;
