import React, { createContext, useState } from 'react';
import { EThemes, ELanguage } from '../constants/enums';

import merge from 'lodash/merge';
import { storeSettings } from '../utils/settings';

export type TSettings = {
  theme: EThemes;
  language: ELanguage;
};

export type TSettingsContext = {
  settings: TSettings;
  saveSettings: (payload: TSettings) => void;
};

export const defaultSettings = {
  theme: EThemes.LIGHT,
  language: ELanguage.FR,
};

export type TSettingsProviderProps = {
  settings: TSettings;
  children: React.ReactNode;
};

const SettingsContext = createContext<TSettingsContext>({
  settings: { theme: EThemes.LIGHT, language: ELanguage.FR },
  saveSettings: () => {},
});

export const SettingsProvider: React.FC<TSettingsProviderProps> = ({
  settings,
  children,
}) => {
  const [currentSettings, setCurrentSettings] = useState<TSettings>(
    settings || defaultSettings,
  );

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
