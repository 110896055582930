import IconKiwiPay from '../assets/icons/logo-kiwi-pay.svg';
import IconFilterSymbol from '../assets/icons/filter-symbol.svg';
import IconCheckMark from '../assets/icons/check-mark.svg';
import IconPrepayment from '../assets/icons/icon-prepayment.svg';
import IconInvoice from '../assets/icons/icon-invoice.svg';
import IconAdhoc from '../assets/icons/icon-adhoc.svg';
import IconBankCard from '../assets/icons/icon-bank-card.svg';
import IconBankTransfer from '../assets/icons/icon-bank-transfer.svg';
import IconCash from '../assets/icons/icon-cash.svg';
import IconCheque from '../assets/icons/icon-cheque.svg';
import IconDirectDebit from '../assets/icons/icon-direct-debit.svg';
import IconPlus from '../assets/icons/icon-plus.svg';
import IconDownload from '../assets/icons/icon-download.svg';
import IconOptions from '../assets/icons/icon-options.svg';
import IconSuccess from '../assets/icons/success-icon.svg';
import IconFailure from '../assets/icons/failure-icon.svg';
import LogoUppli from '../assets/icons/logo-uppli.jpg';
import SearchIcon from '../assets/icons/search-icon.svg';
import SearchWhiteIcon from '../assets/icons/search-icon-white.svg';
import MiniLoader from '../assets/icons/mini-loader.svg';
import RefreshIcon from '../assets/icons/icon-refresh.svg';

import NavRevival from '../assets/icons/nav-revival.svg';
import NavSearch from '../assets/icons/nav-search.svg';
import NavLettering from '../assets/icons/nav-lettering.svg';
import NavMonitoring from '../assets/icons/nav-monitoring.svg';

import ModulePayment from 'assets/icons/module-payment.svg';
import ModuleCustomerCare from 'assets/icons/module-customer-care.svg';

import IconWekiwi from '../assets/icons/ic-wekiwi.svg';

import IconUndraw_add_file2 from '../assets/icons/undraw_add_file2_gvbb.svg';

import IconActorStandard from '../assets/icons/actor_standard.svg';
import IconActorCompany from '../assets/icons/actor_company.svg';

export default {
    'ic-kiwi-pay': IconKiwiPay,
    'ic-actor-standard': IconActorStandard,
    'ic-actor-company': IconActorCompany,
    'ic-filter-symbol': IconFilterSymbol,
    'ic-check-mark': IconCheckMark,
    'ic-success': IconSuccess,
    'ic-failure': IconFailure,
    'ic-logo-uppli': LogoUppli,
    'ic-nav-revival': NavRevival,
    'ic-nav-search': NavSearch,
    'ic-nav-lettering': NavLettering,
    'ic-nav-monitoring': NavMonitoring,
    'ic-plus': IconPlus,
    'ic-download': IconDownload,
    'ic-options': IconOptions,
    'ic-prepayment': IconPrepayment,
    'ic-invoice': IconInvoice,
    'ic-adhoc': IconAdhoc,
    'ic-bank-card': IconBankCard,
    'ic-bank-transfer': IconBankTransfer,
    'ic-cash': IconCash,
    'ic-cheque': IconCheque,
    'ic-direct-debit': IconDirectDebit,
    'ic-search': SearchIcon,
    'ic-search-white': SearchWhiteIcon,
    'mini-loader': MiniLoader,
    'ic-refresh': RefreshIcon,
    'ic-wekiwi': IconWekiwi,
    'ic-undraw_add_file2_gvbb': IconUndraw_add_file2,
    'ic-module-payment': ModulePayment,
    'ic-module-customer-care': ModuleCustomerCare,
};
