import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import i18next from 'i18next';

import { TTranslationsPath } from 'lib/translation';

interface IProps {
    path: TTranslationsPath;
    key?: string;
    keys?: string[];
}

export default function useObjectData<T>({
    path,
    key,
    keys,
}: IProps): {
    t: i18next.TFunction;
    state: T;
} {
    const [t] = useTranslation(path);

    const [state, setState] = useState<any>({});

    useEffect(() => {
        if (keys && keys.length !== 0) {
            const prepare = {};
            keys.forEach((k) => {
                prepare[k] = t(k, {
                    returnObjects: true,
                });
            });

            setState(prepare);
            return;
        }

        if (key) {
            setState({
                [key]: t(key, {
                    returnObjects: true,
                }),
            });
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { t, state };
}
